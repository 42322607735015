/**
 * Libraries
 */
import { useContext, useEffect, useMemo, useState } from "react";
import { Card, Col, Divider, Row, message, Typography, Modal } from "antd";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
//import socket from "../../../api/service/webSocket/socket.js";

/**
 * Modules
 */

import { server } from "../../../api/axios.js";
import { controlPanelContext } from "../../../context/controlPanel";
import PartialViewThermostat from "./partialView";
/**
 * icons
 */
import { CiTempHigh } from "react-icons/ci";
import { WiHumidity } from "react-icons/wi";
import { Line } from "@ant-design/plots";

/**
 * Variaciones
 */
const { Meta } = Card;

const { Title } = Typography;
export default function Thermostat() {
  const { keycloak, inicialiced } = useKeycloak();
  axios.defaults.headers.common["Authorization"] = `Bearer ${keycloak.token}`;
  /**
   * definicion del uso del context del padre
   */
  const [filter, setFilter] = useContext(controlPanelContext);
  /**
   * Hook useMemo para guardar en cache los valores del context
   * Se ejecuata cuando Filter tiene un valor
   */
  const params = useMemo(() => {
    return filter;
  }, [filter]);
  const [messageApi, contextHolder] = message.useMessage();
  const [modalOpen, setModalOpen] = useState(false);

  const [data, setData] = useState({
    thermostat1: {
      series: { temperature: [0], humidity: [0] },
      dataCh: [0],
      math: {
        temperature: { max: 0, min: 0, last: 0, avg: 0 },
        humidity: { max: 0, min: 0, last: 0, avg: 0 },
      },
    },
    thermostat2: {
      series: { temperature: [0], humidity: [0] },
      dataCh: [0],
      math: {
        temperature: { max: 0, min: 0, last: 0, avg: 0 },
        humidity: { max: 0, min: 0, last: 0, avg: 0 },
      },
    },
    outThermostat: {
      series: { temperature: [0], humidity: [0] },
      dataCh: [0],
      math: {
        temperature: { max: 0, min: 0, last: 0, avg: 0 },
        humidity: { max: 0, min: 0, last: 0, avg: 0 },
      },
    },
  });
  const [thermostat1, setThermostat1] = useState({
    temperature: 0,
    humidity: 0,
  });
  const [thermostat2, setThermostat2] = useState({
    temperature: 0,
    humidity: 0,
  });
  const [OutThermostat, setOutThermostat] = useState({
    temperature: 0,
    humidity: 0,
  });
  /**
   * Peticion
   */
  const getData = (param) => {
    axios
      .post(`${server()}/mg/data/thermostat`, param, {
        headers: {
          "Content-Type": "application/json",
          widthCredentials: false,
        },
      })
      .then((response) => {
        setData(response.data);

        const extractThermostatData = (thermostatData) => ({
          temperature: thermostatData.math.temperature.last,
          humidity: thermostatData.math.humidity.last,
        });

        setOutThermostat(extractThermostatData(response.data.outThermostat));
        setThermostat1(extractThermostatData(response.data.thermostat1));
        setThermostat2(extractThermostatData(response.data.thermostat2));
        messageApi.success("Data Loaded Successfully.");
      })
      .catch((e) => {
        messageApi.error("Error loading the data of thermostat.");
        console.log(e);
      });
  };
  /**
   * Card
   */

  const cart2 = [
    {
      id: 1,
      name: <Title level={4}>Thermostat 1 </Title>,
      measurement: [
        {
          name: "Temperature",
          math: data.thermostat1.math.temperature,
          last: thermostat1.temperature,
          unit: "ºC",
        },
        {
          name: "Humidity",
          math: data.thermostat1.math.humidity,
          last: thermostat1.humidity,
          unit: "%",
        },
      ],
      dataCh: data.thermostat1.dataCh,
    },
    {
      id: 2,
      name: <Title level={4}>Air Duct Outlet </Title>,
      measurement: [
        {
          name: "Temperature",
          math: data.thermostat2.math.temperature,
          last: data.thermostat2.math.temperature.last,
          unit: "ºC",
        },
        {
          name: "Humidity",
          math: data.thermostat2.math.humidity,
          last: data.thermostat2.math.humidity.last,
          unit: "%",
        },
      ],
      dataCh: data.thermostat2.dataCh,
    },
    {
      id: 3,
      name: <Title level={4}>Air Return </Title>,
      measurement: [
        {
          name: "Temperature",
          math: data.outThermostat.math.temperature,
          last: OutThermostat.temperature,
          unit: "ºC",
        },
        {
          name: "Humidity",
          math: data.outThermostat.math.humidity,
          last: OutThermostat.humidity,
          unit: "%",
        },
      ],
      dataCh: data.outThermostat.dataCh,
    },
  ];
  const config = {
    data: data.outThermostat.dataCh,
    xField: "date",
    yField: "value",
    seriesField: "name",
    slider: {
      start: 0.5,
      end: 1,
    },
    color: ["#1A2793"],
    autoFit: true,
  };

  useEffect(() => {
    getData({
      $or: [
        { id_device: "cafa5a18099ae1c5" },
        { id_device: "ec742adeb65b05f9" },
        { id_device: "e6c0d9949efe18ff" },
      ],
      date: { $gte: params.date[0], $lte: params.date[1] },
    });
  }, []);

  // useEffect(() => {
  //   const endpoints = [
  //     { id: "cafa5a18099ae1c5", label: "1" },
  //     { id: "ec742adeb65b05f9", label: "3" },
  //     { id: "e6c0d9949efe18ff", label: "2" },
  //   ];

  //   const setupEventSource = ({ id, label }) => {
  //     const es = new EventSource(`${server()}/sse/iot-events/${id}`);
  //     es.onopen = () => {
  //       console.log(`Connected ${label}`);
  //     };

  //     es.onmessage = (event) => {
  //       const newData = JSON.parse(event.data);
  //       if (id === "cafa5a18099ae1c5") {
  //         setThermostat1(newData);
  //         messageApi.success(" Data Thermostat 1 Updated");
  //       }
  //       if (id === "ec742adeb65b05f9") {
  //         setOutThermostat(newData);
  //         messageApi.success("Data Thermostat Outside Updated");
  //       }
  //       if (id === "e6c0d9949efe18ff") {
  //         setThermostat2(newData);
  //         messageApi.success("Data Thermostat 2");
  //       }
  //     };

  //     return es; // Devolvemos la instancia para poder cerrarla luego si es necesario
  //   };

  //   // Iniciar los EventSources
  //   const eventSources = endpoints.map(setupEventSource);

  //   // Opcional: cerrar las conexiones cuando el componente se desmonte
  //   return () => {
  //     eventSources.forEach((es) => es.close());
  //   };
  // }, []);

  return (
    <>
      <div className="layout-content">
        {/**
         * Card de las Variables
         */}
        {contextHolder}
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          {cart2.map((termostat, index, _) => (
            <Col
              key={cart2[index].id}
              xs={24}
              sm={8}
              md={8}
              lg={8}
              xl={8}
              xxl={8}
              className="mb-24"
            >
              <Card title={cart2[index].name} bordered={false}>
                <Row>
                  {cart2[index].measurement.map((Variable, i, _) => (
                    <Col
                      className="mb-24"
                      key={i}
                      xs={24}
                      sm={24}
                      md={12}
                      lg={12}
                      xl={12}
                      xxl={12}
                    >
                      <Card
                        bordered={true}
                        title={cart2[index].measurement[i].name}
                      >
                        <Title level={4}>
                          {cart2[index].measurement[i].name ===
                          "Temperature" ? (
                            <CiTempHigh />
                          ) : (
                            <WiHumidity />
                          )}
                          {cart2[index].measurement[i].last.toFixed(1)}
                          {cart2[index].measurement[i].unit}
                        </Title>
                      </Card>
                    </Col>
                  ))}
                </Row>
                <Divider />
                <PartialViewThermostat
                  dataCh={cart2[index].dataCh}
                  data={cart2[index].measurement}
                />
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
}
