import { useState } from "react";
import BouncingLogo from "../bouncingLogo/bouncingLogo";
import { FloatButton } from "antd";
import { BsFillSunFill } from "react-icons/bs";
import { useKeycloak } from "@react-keycloak/web";

export default function BlackScreen(isActive) {


  //console.log(isActive);
  return (
    <>
      <div className="fullscreen-black">
        <BouncingLogo />
        <FloatButton
          href="/"
          icon={<BsFillSunFill />}
          tooltip={<div>Exit Dark Mode</div>}
        />{" "}
      </div>
    </>
  );
}
