import { useKeycloak } from "@react-keycloak/web";
import { Row, Col, Card, Descriptions } from "antd";

export default function Profile() {
  const { keycloak, inicialized } = useKeycloak();
  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col span={24} md={24} className="mb-24">
            <Row>
              <Col span={12}>
                <Card bordered={false} className="header-solid h-full">
                  <li>
                    <Descriptions
                      title={
                        <h3 className="font-semibold m-0">
                          Profile Information
                        </h3>
                      }
                    >
                      <Descriptions.Item label="Name" span={3}>
                        {keycloak.tokenParsed.given_name}
                      </Descriptions.Item>
                      <Descriptions.Item label="Last Name" span={3}>
                        {keycloak.tokenParsed.family_name}
                      </Descriptions.Item>
                      <Descriptions.Item label="Email" span={3}>
                        {keycloak.tokenParsed.email}
                      </Descriptions.Item>
                    </Descriptions>
                  </li>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}
