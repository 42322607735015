import { CSVLink } from "react-csv";
import { Button } from "antd";
import { FaFileCsv } from "react-icons/fa";
export const ReportCsv = (dataSet) => {
  const headers = [
    { label: "DEVICE", key: "name" },
    { label: "CURRENT L1", key: "L1current" },
    { label: "CURRENT L2", key: "L2current" },
    { label: "CURRENT L3", key: "L3current" },
    { label: "POWER L1", key: "L1power" },
    { label: "POWER L2", key: "L2power" },
    { label: "POWER L3", key: "L3power" },
    { label: "POWER FACTOR L1", key: "L1powerFactor" },
    { label: "POWER FACTOR L2", key: "L2powerFactor" },
    { label: "POWER FACTOR L3", key: "L3powerFactor" },
    {label:"VOLTAGE L1",key:"L1voltage"},
    {label:"VOLTAGE L2",key:"L2voltage"},
    {label:"VOLTAGE L3",key:"L3voltage"},
    {label:"ENERGY L1",key:"L1energy"},
    {label:"ENERGY L2",key:"L2energy"},
    {label:"ENERGY L3",key:"L3energy"},
    {label:"FREQUENCY L1",key:"L1frequency"},
    {label:"FREQUENCY L2",key:"L2frequency"},
    {label:"FREQUENCY L3",key:"L3frequency"},
    { label: "DATE", key: "date" },
   
  ];
  return (
    <>
      <CSVLink
        data={dataSet.dataSet}
        filename={dataSet.fileName}
        headers={headers}
      >
        <Button type="primary" icon={<FaFileCsv />}>
          Generate CSV
        </Button>
      </CSVLink>
    </>
  );
};
