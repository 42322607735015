/**
 * library
 */
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  Card,
  Col,
  DatePicker,
  Divider,
  Modal,
  Row,
  Typography,
  message,
  FloatButton,
  Button,
  Popover,
} from "antd";
/**
 * Modules
 */
import { controlPanelContext } from "../../../context/controlPanel";
import { server } from "../../../api/axios";
import Triphasic from "../partialView/triphasicEquipment/triphasic";
import { GROUP1_VARIABLE } from "../../../helper/dataStructure";
//import GetSuggestion from "../../../api/service/IA/getSuggestion";
/**
 * icons
 */
import {
  TbWaveSawTool,
  TbCircuitVoltmeter,
  TbWaveSine,
  TbXboxA,
  TbBrain,
} from "react-icons/tb";
import { MdOutlineSavings } from "react-icons/md";
import { BsArrowUpCircle } from "react-icons/bs";
import { AiOutlineThunderbolt } from "react-icons/ai";
import { SlEnergy } from "react-icons/sl";
import { GiLightningFrequency } from "react-icons/gi";
import { BiShow } from "react-icons/bi";
//import { io } from "socket.io-client";

/**
 * Constants
 */
const { Meta } = Card;
const { RangePicker } = DatePicker;
const { Title } = Typography;
/**
 *
 * @returns Electrical Network Component
 */
export default function Electrical() {
  const { keycloak, inicialiced } = useKeycloak();
  const [sock, setSock] = useState(null);
  axios.defaults.headers.common["Authorization"] = `Bearer ${keycloak.token}`;
  const [filter, setFilter] = useContext(controlPanelContext);
  const params = useMemo(() => {
    return filter;
  }, [filter]);
  const [messageApi, contextHolder] = message.useMessage();
  const [lastValue, setLastValue] = useState({
    VLL: {
      l1: 0,
      l2: 0,
      l3: 0,
    },
    calculations: {
      currentBalance: { series: [], math: { max: 0, min: 0, avg: 0, last: 0 } },
      powerFactorSum: { series: [], math: { max: 0, min: 0, avg: 0, last: 0 } },
      powerSum: { series: [], math: { max: 0, min: 0, avg: 0, last: 0 } },
      voltAmpereSum: { series: [], math: { max: 0, min: 0, avg: 0, last: 0 } },
      voltageBalance: { series: [], math: { max: 0, min: 0, avg: 0, last: 0 } },
    },
    current: {
      l1: 0,
      l2: 0,
      l3: 0,
    },
    power: {
      l1: 0,
      l2: 0,
      l3: 0,
    },
    powerFactor: {
      l1: 0,
      l2: 0,
      l3: 0,
    },
    reactivePower: {
      l1: 0,
      l2: 0,
      l3: 0,
    },
    voltage: {
      l1: 0,
      l2: 0,
      l3: 0,
    },
    voltAmpere: {
      l1: 0,
      l2: 0,
      l3: 0,
    },
    harmonicV: {
      l1: 0,
      l2: 0,
      l3: 0,
    },
    harmonicI: {
      l1: 0,
      l2: 0,
      l3: 0,
    },
    frequency: {
      l1: 0,
      l2: 0,
      l3: 0,
    },
  });
  const [data, setData] = useState({
    VLL: {
      series: { l1: [0], l2: [0], l3: [0] },
      dataCh: [0],
      math: {
        l1: { max: 0, min: 0, avg: 0, last: lastValue.VLL.l1 },
        l2: { max: 0, min: 0, avg: 0, last: lastValue.VLL.l2 },
        l3: { max: 0, min: 0, avg: 0, last: lastValue.VLL.l3 },
      },
    },
    calculations: {
      currentBalance: { series: [], math: { max: 0, min: 0, avg: 0, last: 0 } },
      powerFactorSum: { series: [], math: { max: 0, min: 0, avg: 0, last: 0 } },
      powerSum: { series: [], math: { max: 0, min: 0, avg: 0, last: 0 } },
      voltAmpereSum: { series: [], math: { max: 0, min: 0, avg: 0, last: 0 } },
      voltageBalance: { series: [], math: { max: 0, min: 0, avg: 0, last: 0 } },
    },
    current: {
      series: { l1: [0], l2: [0], l3: [0] },
      dataCh: [0],
      math: {
        l1: { max: 0, min: 0, avg: 0, last: lastValue.current.l1 },
        l2: { max: 0, min: 0, avg: 0, last: lastValue.current.l2 },
        l3: { max: 0, min: 0, avg: 0, last: lastValue.current.l3 },
      },
    },
    power: {
      series: { l1: [0], l2: [0], l3: [0] },
      dataCh: [0],
      math: {
        l1: { max: 0, min: 0, avg: 0, last: lastValue.power.l1 },
        l2: { max: 0, min: 0, avg: 0, last: lastValue.power.l2 },
        l3: { max: 0, min: 0, avg: 0, last: lastValue.power.l3 },
      },
    },
    powerFactor: {
      series: { l1: [0], l2: [0], l3: [0] },
      dataCh: [0],
      math: {
        l1: { max: 0, min: 0, avg: 0, last: lastValue.powerFactor.l1 },
        l2: { max: 0, min: 0, avg: 0, last: lastValue.powerFactor.l2 },
        l3: { max: 0, min: 0, avg: 0, last: lastValue.powerFactor.l3 },
      },
    },
    reactivePower: {
      series: { l1: [0], l2: [0], l3: [0] },
      dataCh: [0],
      math: {
        l1: { max: 0, min: 0, avg: 0, last: lastValue.reactivePower.l1 },
        l2: { max: 0, min: 0, avg: 0, last: lastValue.reactivePower.l2 },
        l3: { max: 0, min: 0, avg: 0, last: lastValue.reactivePower.l3 },
      },
    },
    voltage: {
      series: { l1: [0], l2: [0], l3: [0] },
      dataCh: [0],
      math: {
        l1: { max: 0, min: 0, avg: 0, last: lastValue.voltage.l1 },
        l2: { max: 0, min: 0, avg: 0, last: lastValue.voltage.l2 },
        l3: { max: 0, min: 0, avg: 0, last: lastValue.voltage.l3 },
      },
    },
    voltAmpere: {
      series: { l1: [0], l2: [0], l3: [0] },
      dataCh: [0],
      math: {
        l1: { max: 0, min: 0, avg: 0, last: lastValue.voltAmpere.l1 },
        l2: { max: 0, min: 0, avg: 0, last: lastValue.voltAmpere.l2 },
        l3: { max: 0, min: 0, avg: 0, last: lastValue.voltAmpere.l3 },
      },
    },
    harmonicV: {
      series: { l1: [0], l2: [0], l3: [0] },
      dataCh: [0],
      math: {
        l1: { max: 0, min: 0, avg: 0, last: lastValue.harmonicV.l1 },
        l2: { max: 0, min: 0, avg: 0, last: lastValue.harmonicV.l2 },
        l3: { max: 0, min: 0, avg: 0, last: lastValue.harmonicV.l3 },
      },
    },
    harmonicI: {
      series: { l1: [0], l2: [0], l3: [0] },
      dataCh: [0],
      math: {
        l1: { max: 0, min: 0, avg: 0, last: lastValue.harmonicI.l1 },
        l2: { max: 0, min: 0, avg: 0, last: lastValue.harmonicI.l2 },
        l3: { max: 0, min: 0, avg: 0, last: lastValue.harmonicI.l3 },
      },
    },
    frequency: {
      series: { l1: [0], l2: [0], l3: [0] },
      dataCh: [0],
      math: {
        l1: { max: 0, min: 0, avg: 0, last: lastValue.frequency.l1 },
        l2: { max: 0, min: 0, avg: 0, last: lastValue.frequency.l2 },
        l3: { max: 0, min: 0, avg: 0, last: lastValue.frequency.l3 },
      },
    },
  });
  const [rsIA, setRsIA] = useState("Ups Try Again Later");
  const [modal, setModal] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const cart = [
    {
      id: 11,
      name: "overconsumption",
      icon: <BsArrowUpCircle />,
      lines: [
        {
          name: "Line 1",
          last:
            lastValue.powerFactor.l1 > 0.95
              ? 0
              : (lastValue.current.l1 * lastValue.voltage.l1 -
                  lastValue.current.l1 *
                    lastValue.voltage.l1 *
                    (lastValue.powerFactor.l1 / 0.95)) /
                1000,
        },
        {
          name: "Line 2",
          last:
            lastValue.powerFactor.l2 > 0.95
              ? 0
              : (lastValue.current.l2 * lastValue.voltage.l2 -
                  lastValue.current.l2 *
                    lastValue.voltage.l2 *
                    (lastValue.powerFactor.l2 / 0.95)) /
                1000,
        },
        {
          name: "Line 3",
          last:
            lastValue.powerFactor.l3 > 0.95
              ? 0
              : (lastValue.current.l3 * lastValue.voltage.l3 -
                  lastValue.current.l3 *
                    lastValue.voltage.l3 *
                    (lastValue.powerFactor.l3 / 0.95)) /
                1000,
        },
      ],

      unit: "kw",
    },
    {
      id: 5,
      name: "Voltage",
      icon: <TbCircuitVoltmeter />,
      lines: [
        {
          name: "Line 1",
          last: lastValue.voltage.l1.toFixed(1),
        },
        {
          name: "Line 2",
          last: lastValue.voltage.l2.toFixed(1),
        },
        {
          name: "Line 3",
          last: lastValue.voltage.l3.toFixed(1),
        },
      ],
      sub: data.voltage.math,
      categories: data.categories,
      series: data.voltage.series,
      dataCh: data.voltage.dataCh,
      unit: "V",
    },
    {
      id: 1,
      name: "Current",
      icon: <TbWaveSine />,
      lines: [
        {
          name: "Line 1",
          last: lastValue.current.l1,
        },
        {
          name: "Line 2",
          last: lastValue.current.l2,
        },
        {
          name: "Line 3",
          last: lastValue.current.l3,
        },
      ],
      sub: data.current.math,
      categories: data.categories,
      series: data.current.series,
      dataCh: data.current.dataCh,
      unit: "A",
    },
    {
      id: 3,
      name: "Power Factor",
      icon: <SlEnergy />,
      lines: [
        {
          name: "Line 1",
          last: lastValue.powerFactor.l1,
        },
        {
          name: "Line 2",
          last: lastValue.powerFactor.l2,
        },
        {
          name: "Line 3",
          last: lastValue.powerFactor.l3,
        },
      ],
      sub: data.powerFactor.math,
      categories: data.categories,
      series: data.powerFactor.series,
      dataCh: data.powerFactor.dataCh,
      unit: "",
    },
    {
      id: 2,
      name: "Power",
      icon: <AiOutlineThunderbolt />,
      lines: [
        {
          name: "Line 1",
          last: lastValue.power.l1,
        },
        {
          name: "Line 2",
          last: lastValue.power.l2,
        },
        {
          name: "Line 3",
          last: lastValue.power.l3,
        },
      ],
      sub: data.power.math,
      categories: data.categories,
      series: data.power.series,
      dataCh: data.power.dataCh,
      unit: "kW",
    },

    {
      id: 4,
      name: "Reactive Power",
      icon: <TbWaveSawTool />,
      lines: [
        {
          name: "Line 1",
          last: lastValue.reactivePower.l1.toFixed(1),
        },
        {
          name: "Line 2",
          last: lastValue.reactivePower.l2.toFixed(1),
        },
        {
          name: "Line 3",
          last: lastValue.reactivePower.l3.toFixed(1),
        },
      ],
      sub: data.reactivePower.math,
      categories: data.categories,
      series: data.reactivePower.series,
      dataCh: data.reactivePower.dataCh,
      unit: "kVA",
    },

    {
      id: 6,
      name: "Voltage Between Lines",
      icon: <TbCircuitVoltmeter />,
      lines: [
        {
          name: "L1-L2",
          last: lastValue.VLL.l1.toFixed(1),
        },
        {
          name: "L2-L3",
          last: lastValue.VLL.l2.toFixed(1),
        },
        {
          name: "L3-L1",
          last: lastValue.VLL.l3.toFixed(1),
        },
      ],
      sub: data.VLL.math,
      categories: data.categories,
      series: data.VLL.series,
      dataCh: data.VLL.dataCh,
      unit: "V",
    },
    {
      id: 7,
      name: "Volt Ampere",
      icon: <TbXboxA />,
      lines: [
        {
          name: "Line 1",
          last: lastValue.voltAmpere.l1.toFixed(),
        },
        {
          name: "Line 2",
          last: lastValue.voltAmpere.l2.toFixed(),
        },
        {
          name: "Line 3",
          last: lastValue.voltAmpere.l3.toFixed(),
        },
      ],
      sub: data.voltAmpere.math,
      categories: data.categories,
      series: data.voltAmpere.series,
      dataCh: data.voltAmpere.dataCh,
      unit: "kVA",
    },
    {
      id: 8,
      name: "Current Harmonics",
      icon: <TbWaveSawTool />,
      lines: [
        {
          name: "Line 1",
          last: data.harmonicI.math.l1.last.toFixed(2),
        },
        {
          name: "Line 2",
          last: data.harmonicI.math.l2.last.toFixed(2),
        },
        {
          name: "Line 3",
          last: data.harmonicI.math.l3.last.toFixed(2),
        },
      ],
      sub: data.harmonicI.math,
      categories: data.categories,
      series: data.harmonicI.series,
      dataCh: data.harmonicI.dataCh,
      unit: "%",
    },
    {
      id: 9,
      name: "Voltage Harmonics",
      icon: <TbWaveSawTool />,
      lines: [
        {
          name: "Line 1",
          last: data.harmonicV.math.l1.last.toFixed(2),
        },
        {
          name: "Line 2",
          last: data.harmonicV.math.l2.last.toFixed(2),
        },
        {
          name: "Line 3",
          last: data.harmonicV.math.l3.last.toFixed(2),
        },
      ],
      sub: data.harmonicV.math,
      categories: data.categories,
      series: data.harmonicV.series,
      dataCh: data.harmonicV.dataCh,
      unit: "%",
    },
    {
      id: 10,
      name: "Frequency",
      icon: <GiLightningFrequency />,
      lines: [
        {
          name: "Line 1",
          last: lastValue.frequency.l1.toFixed(),
        },
        {
          name: "Line 2",
          last: lastValue.frequency.l2.toFixed(),
        },
        {
          name: "Line 3",
          last: lastValue.frequency.l3.toFixed(),
        },
      ],
      sub: data.frequency.math,
      categories: data.categories,
      series: data.frequency.series,
      dataCh: data.frequency.dataCh,
      unit: "Hz",
    },
  ];

  let question = {
    question: `Diagnostico de la Red Electrica con los siguientes valores en sus lineas:Factor de Potencia:  L1:${data.powerFactor.math.l1.last}, L2: ${data.powerFactor.math.l2.last}, L3: ${data.powerFactor.math.l3.last},  Potencia reactiva: L1: ${data.reactivePower.math.l1.last}, L2: ${data.reactivePower.math.l2.last}, L3: ${data.reactivePower.math.l3.last},  Potencia: L1: ${data.power.math.l1.last}, L2: ${data.power.math.l2.last}, L3: ${data.power.math.l3.last}, Corriente: L1: ${data.current.math.l1.last}, L2: ${data.current.math.l2.last}, L3: ${data.current.math.l3.last}, Voltaje: L1: ${data.voltage.math.l1.last},L2: ${data.voltage.math.l2.last}, L3: ${data.voltage.math.l3.last}, Frequecia: L1: ${data.frequency.math.l1.last}, L2: ${data.frequency.math.l2.last}, L3: ${data.frequency.math.l3.last}, Harmonicos de corriente: L1: ${data.harmonicI.math.l1.last}, L2: ${data.harmonicI.math.l2.last}, L3: ${data.harmonicI.math.l3.last}, Harmonicos de voltaje: L1: ${data.harmonicV.math.l1.last}, L2: ${data.harmonicV.math.l2.last}, L3: ${data.harmonicV.math.l3.last}, Voltio Ampereos: L1: ${data.voltAmpere.math.l1.last}, L2: ${data.voltAmpere.math.l2.last}, L3: ${data.voltAmpere.math.l3.last}, Revisar si existen desbalances en la red electrica. limita tu respuesta a 400 caracteres`,
  };

  const getData = (param) => {
    axios
      .post(`${server()}/mg/data/electrical`, param, {
        headers: {
          "Content-Type": "application/json",
          widthCredentials: false,
        },
      })
      .then((response) => {
        setData(response.data);
        const extractLastValue = (key) => {
          const dataSegment = response.data[key].math;
          return {
            l1: dataSegment.l1.last,
            l2: dataSegment.l2.last,
            l3: dataSegment.l3.last,
          };
        };
        const updatedData = GROUP1_VARIABLE.reduce((acc, key) => {
          acc[key] = extractLastValue(key);
          return acc;
        }, {});
        setLastValue(updatedData);
        messageApi.success("Data Loaded Successfully.");
      })
      .catch((e) => {
        messageApi.error("Error loading the data.");
      });
  };
  const getSugest = async () => {
    messageApi.open({
      type: "loading",
      content: "Getting Suggestion in progress..",
      duration: 500,
    });
    await axios
      .post(`${server()}/ia/sugestion`, JSON.stringify(question), {
        headers: {
          "Content-Type": "application/json",
          widthCredentials: false,
        },
      })
      .then((response) => {
        messageApi.success("Suggestion Loaded");
        setRsIA(response.data);

        setModal(true);
      })
      .catch((e) => {
        messageApi.close();
        messageApi.error(question);
        console.log(question);
      });
  };

  // useEffect(() => {
  //   //console.log("Connected", message);
  //   // const eventSource = new EventSource(
  //   //   `${server()}/sse/iot-events/c4f412fa8244f8c5`
  //   // );
  //   // eventSource.onopen = () => {
  //   //   console.log("Connected");
  //   // };
  //   // eventSource.onmessage = (event) => {
  //   //   const newData = JSON.parse(event.data);
  //   //   const updatedData = GROUP1_VARIABLE.reduce((accumulator, key) => {
  //   //     accumulator[key] = ["L1", "L2", "L3"].reduce((obj, l) => {
  //   //       obj[`l${l[1]}`] = newData[key][l];
  //   //       return obj;
  //   //     }, {});
  //   //     return accumulator;
  //   //   }, {});
  //   //   setLastValue(updatedData);
  //   //   messageApi.success("Data Updated");
  //   // };
  //   // eventSource.onerror = (error) => {
  //   //   console.log(error);
  //   // };
  //   return () => {
  //     eventSource.close();
  //   };
  // }, []);
  useEffect(() => {
    getData({
      id_device: "c4f412fa8244f8c5",
      date: { $gte: params.date[0], $lte: params.date[1] },
    });
  }, []);
  return (
    <>
      <div className="layout-content">
        {contextHolder}
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          {cart.map((v, index, _) => {
            if (v.name === "overconsumption") {
              return (
                <Col
                  key={index}
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                  className="mb-24"
                >
                  <Popover
                    title="Information"
                    placement="bottom"
                    content={
                      <p>
                        Instantaneous value of overconsumption due to having a
                        power factor lower than 0.95
                      </p>
                    }
                  >
                    <Card bordered={true} title="Overconsumption">
                      <Row>
                        {cart[index].lines.map((line, index) => (
                          <Col
                            key={index}
                            className="mb-24"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={7}
                            xl={8}
                            xxl={8}
                          >
                            <Row>
                              <Title level={5}>
                                {v.icon}{" "}
                                {` ${line.name}: 
                            `}
                              </Title>
                            </Row>
                            <Title level={5}>
                              {new Intl.NumberFormat().format(line.last)}
                              {` ${v.unit}`}
                            </Title>
                          </Col>
                        ))}
                      </Row>
                      <Divider />
                      <Row>
                        <Col
                          className="mb-24"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          xxl={24}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <p></p>
                          <Button
                            type="primary"
                            onClick={() => setModal2Open(true)}
                          >
                            Show Details {<BiShow size={25} />}
                          </Button>
                        </Col>
                      </Row>
                      <Modal
                        title="Electricity Consumption Calculator"
                        centered
                        open={modal2Open}
                        onOk={() => setModal2Open(false)}
                        onCancel={() => setModal2Open(false)}
                      >
                        <h1>overconsumption Calculator</h1>
                      </Modal>
                    </Card>
                  </Popover>
                </Col>
              );
            } else {
              return (
                <Col
                  key={index}
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                  className="mb-24"
                >
                  <Card bordered={true} title={v.name}>
                    <Row>
                      {cart[index].lines.map((line, index) => (
                        <Col
                          key={index}
                          className="mb-24"
                          xs={8}
                          sm={8}
                          md={8}
                          lg={7}
                          xl={8}
                          xxl={8}
                        >
                          <Row>
                            <Title level={5}>
                              {v.icon}{" "}
                              {` ${line.name}: 
                           `}
                            </Title>
                          </Row>
                          <Title level={5}>
                            {` 
                           ${line.last} ${v.unit}`}
                          </Title>
                        </Col>
                      ))}
                    </Row>
                    <Divider />
                    <Triphasic
                      dataCh={cart[index].dataCh}
                      unit={v.unit}
                      data={cart[index].sub}
                    />
                  </Card>
                </Col>
              );
            }
          })}
        </Row>
        <FloatButton
          icon={<TbBrain />}
          onClick={() => {
            getSugest();
          }}
          tooltip="Get Sugestions from IA"
        />
        <Modal
          title="Get Sugestions from IA"
          centered
          open={modal}
          onOk={() => setModal(false)}
          onCancel={() => setModal(false)}
        >
          <p>{rsIA}</p>
        </Modal>
      </div>
    </>
  );
}
