/* eslint-disable react/prop-types */
/**
 * Hooks React
 */
import { useState, useEffect } from "react";

/**
 * Time
 */
import moment from "moment-timezone";
/**
 * Link para las Rutas
 */
import { Link } from "react-router-dom";
/**
 * iconos
 */
import {
  FaPowerOff,
  FaRegBell,
  FaUser,
  FaClock,
  FaAngleDown,
  FaRegSun,
  FaBars,
} from "react-icons/fa";
import { BsFillMoonStarsFill, BsFillSunFill } from "react-icons/bs";
import { SearchOutlined, CheckCircleOutlined } from "@ant-design/icons";
import IDT from "../../assets/images/idtolu.png";
/**Componentes Antd */
import {
  Avatar,
  Badge,
  ConfigProvider,
  Button,
  Col,
  Drawer,
  Row,
  Space,
  Switch,
  Typography,
  Radio,
  Dropdown,
  Breadcrumb,
  Input,
  FloatButton,
} from "antd";
/**
 * Otros Componentes
 */
import BlackScreen from "../blackScreen/blackScreen";
import { useKeycloak } from "@react-keycloak/web";
/**
 *
 * @param {*} props
 * @returns
 */

export default function Header(props) {
  /**
   * Tipografia
   */
  const { Title, Text } = Typography;
  /**
   * Control de los valores del Reloj
   */
  const [dataTime, setDataTime] = useState(
    `${moment().format("LL")} ${moment().format("LTS")}`
  );
  /**
   * Hooks
   */
  const [visible, setVisible] = useState(false);
  const [sidenavType, setSidenavType] = useState("transparent");
  const showDrawer = () => setVisible(true);
  const hideDrawer = () => setVisible(false);

  const { keycloak, inicialized } = useKeycloak();
  /**
   * Dark Mode
   */
  const [isActive, setIsActive] = useState(false);
  /**
   * Menu Desplegable
   */
  const items = [
    {
      key: "0",
      label: (
        <Link to="/profile" className="nav-link">
          {" "}
          <Button type="primary">
            {<FaUser />}
            Profile
          </Button>{" "}
        </Link>
      ),
    },
    {
      key: "1",
      label: (
        <Link to="/setting" className="nav-link">
          <Button type="primary">
            {<FaRegSun />}
            Setting
          </Button>{" "}
        </Link>
      ),
    },
    {
      label: (
        <Button
          type="primary"
          onClick={() =>
            keycloak.logout({
              redirectUri: window.location.origin,
            })
          }
        >
          {<FaPowerOff />}
          <span>Logout</span>
        </Button>
      ),
      key: "3",
    },
  ];
  /**
   * control del Scroll
   */
  useEffect(() => window.scrollTo(0, 0), []);
  /**
   * Reloj
   */
  useEffect(() => {
    const timer = setInterval(() => {
      const date = new Date();
      setDataTime(`${moment().format("LL")} ${moment().format("LTS")}`);
    }, 1000);
  }, []);

  return (
    <>
      {isActive && <BlackScreen isActive={isActive}></BlackScreen>}

      {!isActive && (
        <>
          <Row gutter={[24, 0]}>
            <Col span={24} md={6}>
              <Breadcrumb
                separator="/"
                items={[
                  { title: <Link to="/">home</Link> },
                  { title: props.name },
                ]}
              />

              <div className="ant-page-header-heading">
                <span
                  className="ant-page-header-heading-title"
                  style={{ textTransform: "capitalize" }}
                >
                  {props.subName}
                </span>
              </div>
            </Col>
            <Col span={24} md={18} className="header-control">
              <Space size={[8, 16]} wrap>
                <Button
                  type="link"
                  className="sidebar-toggler"
                  onClick={() => props.onPress()}
                >
                  {<FaBars />}
                </Button>
                <Input
                  className="header-search"
                  placeholder="Type here..."
                  prefix={<SearchOutlined />}
                />
                <Button shape="round" icon={<FaClock />}>
                  <span align="center">{dataTime}</span>
                </Button>
                {/* <Badge title="Notification" count={99}>
            {bell}
          </Badge>{" "} */}
                <Avatar size={40} style={{ background: "#FA8C0B" }}>
                  {keycloak.tokenParsed.given_name}
                </Avatar>
                <Dropdown
                  menu={{ items }}
                  placement="bottomRight"
                  arrow={{ pointAtCenter: true }}
                >
                  <Space>
                    {keycloak.tokenParsed.given_name}
                    <FaAngleDown />
                  </Space>
                </Dropdown>
                <Switch
                  onClick={(props) => {
                    setIsActive(props);
                  }}
                  checkedChildren={<BsFillSunFill />}
                  unCheckedChildren={<BsFillMoonStarsFill />}
                />
              </Space>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
