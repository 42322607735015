/**
 * Componentes de Antd
 */
import {
  Avatar,
  Button,
  Card,
  Col,
  Row,
  Table,
  Typography,
  Timeline,
  message,
} from "antd";
import { useState, useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";

/**
 * Modules
 */

import { server } from "../../api/axios.js";
/**
 * Iconos
 */

import { AiFillAlert, AiOutlineMail } from "react-icons/ai";
import { BsWhatsapp } from "react-icons/bs";

const { Title } = Typography;
/**
 * Columns Table
 */
const columns = [
  {
    title: "DATE",
    key: "date",
    dataIndex: "date",
  },
  {
    title: "STATUS",
    key: "status",
    dataIndex: "status",
  },
  {
    title: "STATE",
    key: "state",
    dataIndex: "state",
  },
  {
    title: "CHANNEL",
    dataIndex: "channel",
    key: "channel",
  },
  {
    title: "DESCRIPTION",
    dataIndex: "message",
    key: "message",
    width: 50,
    height: 200,
  },
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
];

export default function History() {
  const { keycloak, inicialiced } = useKeycloak();
  axios.defaults.headers.common["Authorization"] = `Bearer ${keycloak.token}`;
  const [messageApi, contextHolder] = message.useMessage();
  const [data, setData] = useState([]);
  const [dataTimeLine, setDataTimeLine] = useState([]);
  const getAlert = async () => {
    await axios
      .get(`${server()}/mg/data/alert`)
      .then((res) => {
        setData(res.data);
        messageApi.success("Data Loaded Successfully.");
      })
      .catch((e) => {
        messageApi.error("Error loading the data.");
      });
  };
  data.forEach((item) => {
    item.key = item.id;
    item.status =
      item.status === "Success" ? (
        <>
          <Button
            type="primary"
            style={{
              backgroundColor: "#f6ffed",
              color: "#389e0d",
            }}
            className="tag-primary"
          >
            Success
          </Button>
        </>
      ) : (
        <>
          <Button
            className="tag-badge"
            type="primary"
            style={{
              backgroundColor: "#fff2f0",
              color: "#d9363e",
            }}
          >
            Fail
          </Button>
        </>
      );
    item.channel = (
      <Avatar.Group>
        <Avatar
          icon={<AiOutlineMail />}
          style={{
            backgroundColor: "#e6f4ff",
            color: "#1677ff",
          }}
        />
        <Avatar
          icon={<BsWhatsapp />}
          style={{
            backgroundColor: "#f6ffed",
            color: "#52c41a",
          }}
        />
      </Avatar.Group>
    );
    item.state =
      item.state === "Open" ? (
        <>
          <Button className="tag-primary" type="primary">
            Open
          </Button>
        </>
      ) : (
        <>
          <Button className="tag-badge">Closed</Button>
        </>
      );
    dataTimeLine.push({
      children: `${item.date}-${item.message} `,
      dot: <AiFillAlert style={{ fontSize: "16px" }} />,
    });
  });

  useEffect(() => {
    getAlert();
  }, []);

  return (
    <>
      <div className="layout-content">
        {contextHolder}
        <Row gutter={[24, 0]}>
          <Col className="mb-24" xs={24} xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Alert History"
            >
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={data}
                  pagination={true}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24} xl={24} className="mb-24">
            <Card className="criclebox" title="Time Line">
              <Timeline mode="alternate" items={dataTimeLine} />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
