import { Button, Row, Col, Modal, Card, Statistic } from "antd";
import { useEffect, useState } from "react";
import { AiOutlineArrowDown } from "react-icons/ai";
import { IoArrowUpSharp } from "react-icons/io5";
import { BiShow } from "react-icons/bi";
import { TbMathAvg } from "react-icons/tb";
import { WiHumidity } from "react-icons/wi";
import { CiTempHigh } from "react-icons/ci";
import { Line } from "@ant-design/plots";
const { Meta } = Card;

export default function PartialViewThermostat(data) {
  const [modalOpen, setModalOpen] = useState(false);
  const [value, setValue] = useState({
    data: [
      {
        name: "Temperature",
        icon2: <CiTempHigh />,
        math: { max: 0, min: 0, avg: 0, last: 0 },
        last: 0,
        unit: "ºC",
      },
      {
        name: "Humidity",
        icon2: <WiHumidity />,
        math: { max: 0, min: 0, avg: 0, last: 0 },
        last: 0,
        unit: "%",
      },
    ],
    dataCh: [],
  });
  const cart = [
    {
      id: 1,
      name: "Temperature",
      values: {
        avg: value.data[0].math.avg.toFixed(),
        max: value.data[0].math.max.toFixed(),
        min: value.data[0].math.min.toFixed(),
      },
      unit: "°C",
    },
    {
      id: 2,
      name: "Humidity",
      values: {
        avg: value.data[1].math.avg.toFixed(),
        max: value.data[1].math.max.toFixed(),
        min: value.data[1].math.min.toFixed(),
      },
      unit: "%",
    },
  ];
  const config = {
    data: value.dataCh,
    xField: "date",
    yField: "value",
    seriesField: "name",
    slider: {
      start: 0.5,
      end: 1,
    },
    color: ["#FFC73A", "#1A2793"],
    autoFit: true,
  };

  useEffect(() => {
    setValue(data);
  }, [data]);

  return (
    <>
      <Row>
        <Col
          className="mb-24"
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          xxl={24}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button type="primary" onClick={() => setModalOpen(true)}>
            Show Details {<BiShow size={25} />}
          </Button>
          <Modal
            title="Details to Variable"
            width={"80%"}
            centered
            open={modalOpen}
            onOk={() => setModalOpen(false)}
            onCancel={() => setModalOpen(false)}
          >
            <Row>
              {cart.map((cart) => (
                <Col
                  key={cart.id}
                  className="mb-24"
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                >
                  <Card title={cart.name} bordered={true}>
                    <Row>
                      <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                        <Card bordered={true}>
                          <Statistic
                            value={`${cart.values.avg} ${cart.unit}`}
                            valueStyle={{
                              color: "#08979c",
                            }}
                            prefix={<TbMathAvg />}
                          />
                          <Meta description={"Avg"} />
                        </Card>
                      </Col>
                      <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                        <Card bordered={true}>
                          <Statistic
                            value={`${cart.values.max} ${cart.unit}`}
                            valueStyle={{
                              color: "#52C41A",
                            }}
                            prefix={<IoArrowUpSharp />}
                          />
                          <Meta description={"Max"} />
                        </Card>
                      </Col>
                      <Col
                        className="mb-24"
                        xs={8}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={8}
                        xxl={8}
                      >
                        <Card bordered={true}>
                          <Statistic
                            value={`${cart.values.min} ${cart.unit}`}
                            valueStyle={{
                              color: "red",
                            }}
                            prefix={<AiOutlineArrowDown />}
                          />
                          <Meta description={"Min"} />
                        </Card>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ))}
            </Row>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Card title="Comparison Chart" bordered={true}>
                  <Line {...config} />
                </Card>
              </Col>
            </Row>
          </Modal>
        </Col>
      </Row>
    </>
  );
}
