import { Menu } from "antd";
import IDT from "../../assets/images/idtolu.png";
import ARA from "../../assets/images/logo-ara.svg";
import DEMO from "../../assets/images/Emtiot.png";
import SIC from "../../assets/images/logo.png";
import {
  FaCube,
  FaHistory,
  FaRegChartBar,
  FaTh,
  FaRegBell,
} from "react-icons/fa";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
function getItem(key, children, label) {
  return {
    key,
    children,
    label,
  };
}
/**
 *
 * @param {Configuracion con el proveedor de identidad} props
 * @returns
 */

export default function SideNav(props) {
  const { keycloak, initialized } = useKeycloak();
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  let items;
  let brand;
  function GenerateLinkItem(id, path, icon, label) {
    return getItem(
      id,
      null,
      <Link to={path} className="nav-link">
        <span
          className="icon"
          style={{ background: page === path.substring(1) ? props.color : "" }}
        >
          {icon}
        </span>
        <span className="label">{label}</span>
      </Link>
    );
  }
  const roleConfig = {
    "super-admin": [
      { path: "/", icon: <FaTh />, label: "Dashboard" },
      {
        path: "/device",
        icon: <AiOutlineFundProjectionScreen />,
        label: "Device",
      },
      { path: "/report", icon: <FaRegChartBar />, label: "Report" },

      { path: "/history", icon: <FaRegBell />, label: "History" },
      { path: "/block", icon: <FaCube />, label: "Blockchain" },
      { path: "/black", icon: <FaHistory />, label: "Black Screen" },
    ],
    admin: [
      { path: "/", icon: <FaTh />, label: "Dashboard" },
      {
        path: "/device",
        icon: <AiOutlineFundProjectionScreen />,
        label: "Device",
      },
      { path: "/report", icon: <FaRegChartBar />, label: "Report" },

      { path: "/history", icon: <FaRegBell />, label: "History" },
      { path: "/block", icon: <FaCube />, label: "Blockchain" },
      { path: "/black", icon: <FaHistory />, label: "Black Screen" },
    ],
    viewer: [
      { path: "/", icon: <FaTh />, label: "Dashboard" },
      {
        path: "/device",
        icon: <AiOutlineFundProjectionScreen />,
        label: "Device",
      },
      { path: "/report", icon: <FaRegChartBar />, label: "Report" },

      { path: "/history", icon: <FaRegBell />, label: "History" },
      { path: "/block", icon: <FaCube />, label: "Blockchain" },
      { path: "/black", icon: <FaHistory />, label: "Black Screen" },
    ],
    demo: [
      { path: "/", icon: <FaTh />, label: "Dashboard" },
      {
        path: "/device",
        icon: <AiOutlineFundProjectionScreen />,
        label: "Device",
      },
      { path: "/report", icon: <FaRegChartBar />, label: "Report" },

      { path: "/history", icon: <FaRegBell />, label: "History" },
      { path: "/block", icon: <FaCube />, label: "Blockchain" },
      { path: "/black", icon: <FaHistory />, label: "Black Screen" },
    ],
  };

  let currentRole = null;
  if (keycloak.hasResourceRole("super-admin")) {
    currentRole = "super-admin";
    brand = IDT;
  } else if (keycloak.hasResourceRole("admin")) {
    currentRole = "admin";
    brand = SIC;
  } else if (keycloak.hasResourceRole("viewer")) {
    currentRole = "viewer";
    brand = SIC;
  } else if (keycloak.hasResourceRole("demo")) {
    currentRole = "demo";
    brand = DEMO;
  }

  if (currentRole && roleConfig[currentRole]) {
    items = roleConfig[currentRole].map((item, index) =>
      GenerateLinkItem((index + 1).toString(), item.path, item.icon, item.label)
    );
  }

  /**
   *
   */

  return (
    <>
      <div className="brand">
        <img src={brand} width="170px" height="250px" alt="" />
      </div>
      <div>
        <Menu mode="inline" theme="light" items={items} />
      </div>
    </>
  );
}
