import { Select, Space, Card, Col, Row, DatePicker } from "antd";
import { useState, useContext } from "react";
/**
 * import del context
 */
import { controlPanelContext } from "../../context/controlPanel.js";
import moment from "moment";
const { RangePicker } = DatePicker;
export default function ControlPanel() {
  /**
   * definicion del context del padre para poder usarlo
   */
  const [filter, setFilter] = useContext(controlPanelContext);
  /**
   * List Option
   */
  const optionDepartament = [
    {
      label: `Sucre`,
      value: `Sucre`,
    },
  ];
  const optionCity = [
    {
      label: `Santiago de Tolu`,
      value: `Santiago de Tolu`,
    },
  ];
  const optionStores = [
    {
      label: `Store 1`,
      value: `Store 1`,
    },
  ];
  /**
   * Handles variables del Control panel
   * capturan el valor y almacenan el valor anterior de cada variable
   */

  const handleChangeDepartament = (departament) => {
    setFilter((prevState) => ({
      ...prevState,
      departament: departament,
    }));
  };
  const handleChangeCity = (city) => {
    setFilter((prevState) => ({
      ...prevState,
      city: city,
    }));
  };
  const handleChangeStore = (store) => {
    setFilter((prevState) => ({
      ...prevState,
      store: store,
    }));
  };
  const handleChangeDate = (date) => {
    setFilter((prevState) => ({
      ...prevState,
      date: [moment(date[0]).valueOf(), moment(date[1]).valueOf()],
    }));
  };
  return (
    <>
      <div className="layout-content">
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <Card bordered={false}>
              <Space size={150}>
                {/* <RangePicker
                  onChange={handleChangeDate}
                  style={{ width: "250px" }}
                /> */}
                <Select
                  name="departament"
                  onChange={handleChangeDepartament}
                  style={{ width: "125px" }}
                  options={optionDepartament}
                  placeholder="Department"
                />
                <Select
                  onChange={handleChangeCity}
                  name="municipality"
                  style={{ width: "125px" }}
                  options={optionCity}
                  placeholder="Municipality"
                />
                <Select
                  onChange={handleChangeStore}
                  name="store"
                  style={{ width: "125px" }}
                  options={optionStores}
                  placeholder="Store"
                />
              </Space>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
