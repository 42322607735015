import moment from "moment";
import React, { useState } from "react";
/**
 * Definicion del Contexto
 */
export const controlPanelContext = React.createContext({});
/**
 * Definicion del context Provider
 */
export default function ControlPanelProvider({ children }) {
  /**
   * hook para capturar los estados de las variables
   */
  const [filter, setFilter] = useState({
    date: [moment(1710574185087).subtract(1, "days").valueOf(), 1710574185087],
  });
  /**
   * se retorna el context provider y como propiedad los calores y metodo del hook
   */
  // console.log(filter);
  return (
    <controlPanelContext.Provider value={[filter, setFilter]}>
      {children}
    </controlPanelContext.Provider>
  );
}
