/**
 * iconos
 */
import { DashboardOutlined } from "@ant-design/icons";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { MdAir } from "react-icons/md";
import { TbAirConditioning, TbFridge } from "react-icons/tb";
import { GiCeilingLight, GiElectric } from "react-icons/gi";

import { CiTempHigh } from "react-icons/ci";
/**
 * Componentes Antd
 */
import { Card, Col, Row, Typography } from "antd";
import { Link } from "react-router-dom";

const { Title } = Typography;
// const { Meta } = Card;
/**
 *
 * @returns Vista Device
 */
export default function Device() {
  const cart = [
    //Analizador Siemens
    {
      deviceName: "Electrical Network",
      status: "OK",
      bnb: "bnb2",
      path: "/device/electrical",
      actions: [
        <Link to="/device/electrical" key="view">
          <DashboardOutlined title="View" />
        </Link>,
        <Link to="#" key="info">
          <AiOutlineInfoCircle title="Show Info" />
        </Link>,
      ],
      iconDevice: <GiElectric size={48} />,
    },

    /**A3 Luces */
    {
      deviceName: "Lights",
      status: "OK",
      bnb: "bnb2",
      path: "/device/lights",
      actions: [
        <Link to="/device/lights" key="view">
          <DashboardOutlined title="View" />
        </Link>,
        <Link to="#" key="info">
          <AiOutlineInfoCircle title="Show Info" />
        </Link>,
      ],
      iconDevice: <GiCeilingLight size={48} />,
    },
    /**
     * Temperatura Neveras
     */ {
      deviceName: "Refrigerators",
      status: "OK",
      bnb: "bnb2",
      path: "/device/refrigerator",
      actions: [
        <Link to="/device/refrigerator" key="view">
          <DashboardOutlined title="View" />
        </Link>,
        <Link to="#" key="info">
          <AiOutlineInfoCircle title="Show Info" />
        </Link>,
      ],
      iconDevice: <TbFridge size={48} />,
    },
    /** A2 Aires */
    {
      deviceName: "Air Conditioner",
      status: "OK",
      bnb: "bnb2",
      path: "/device/airconditioner",
      actions: [
        <Link to="/device/airconditioner" key="view">
          <DashboardOutlined title="View" />
        </Link>,
        <Link to="#" key="info">
          <AiOutlineInfoCircle title="Show Info" />
        </Link>,
      ],
      iconDevice: <TbAirConditioning size={48} />,
    },
    /** A4 Cotinas de Aire*/
    {
      deviceName: "Air Curtains",
      status: "OK",
      bnb: "bnb2",
      path: "/device/aircurtain",
      actions: [
        <Link to="/device/aircurtain" key="view">
          <DashboardOutlined title="View" />
        </Link>,
        <Link to="#" key="info">
          <AiOutlineInfoCircle title="Show Info" />
        </Link>,
      ],
      iconDevice: <MdAir size={48} />,
    } /** Thermostat*/,
    {
      deviceName: "Thermostat",
      status: "OK",
      bnb: "bnb2",
      path: "/device/thermostat",
      actions: [
        <Link to="/device/thermostat" key="view">
          <DashboardOutlined title="View" />
        </Link>,
        <Link to="#" key="info">
          <AiOutlineInfoCircle title="Show Info" />
        </Link>,
      ],
      iconDevice: <CiTempHigh size={48} />,
    },
  ];

  return (
    <>
      <div className="layout-content">
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          {cart.map((c, index) => (
            <Col
              key={index}
              xs={24}
              sm={24}
              md={12}
              lg={6}
              xl={6}
              className="mb-24"
            >
              <Link to={c.path}>
                <Card
                  key={c.key}
                  bordered={false}
                  className="criclebox"
                  actions={c.actions}
                >
                  <div className="number">
                    <Row align="middle" gutter={[24, 0]}>
                      <Col xs={18}>
                        <Title level={5}>{c.deviceName}</Title>
                        <small className={c.bnb}>{c.status}</small>
                      </Col>
                      <Col xs={6}>
                        <div className="icon-box">{c.iconDevice}</div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
}
