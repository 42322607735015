/**
 * Definición de URL Servidores
 */
/**Production */
const host = "https://iot-backend-ara.idtolu.net"
/**Development */
//const host = "http://10.0.100.224:31904";
/**Local */
//const host = "http://localhost:3134";

/**
 *
 * @returns path Server Local
 */
export function server() {
  const server = host;
  return server;
}
