/**
 * Library
 */
import {
  Card,
  Col,
  Row,
  Typography,
  Divider,
  message,
  FloatButton,
  Modal,
} from "antd";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";

/**
 * Module
 */
import { server } from "../../../api/axios";
import { useContext, useEffect, useMemo, useState } from "react";
import { controlPanelContext } from "../../../context/controlPanel";
//import socket from "../../../api/service/webSocket/socket";
import {
  TbBrain,
  TbCircuitVoltmeter,
  TbWaveSine,
  TbXboxA,
} from "react-icons/tb";
import { AiOutlineThunderbolt } from "react-icons/ai";
import { SlEnergy } from "react-icons/sl";
import { GiLightningFrequency } from "react-icons/gi";
import Triphasic from "../partialView/triphasicEquipment/triphasic";
import { GROUP2_VARIABLE } from "../../../helper/dataStructure";

const { Title } = Typography;
export default function AirConditioner() {
  const { keycloak, inicialiced } = useKeycloak();
  axios.defaults.headers.common["Authorization"] = `Bearer ${keycloak.token}`;
  const [filter, setFilter] = useContext(controlPanelContext);
  const [messageApi, contextHolder] = message.useMessage();
  const [rsIA, setRsIA] = useState("Ups Try Again Later");
  const [modal, setModal] = useState(false);
  const [lastValue, setLastValue] = useState({
    current: {
      l1: 0,
      l2: 0,
      l3: 0,
    },
    power: {
      l1: 0,
      l2: 0,
      l3: 0,
    },
    powerFactor: {
      l1: 0,
      l2: 0,
      l3: 0,
    },

    voltage: {
      l1: 0,
      l2: 0,
      l3: 0,
    },
    energy: {
      l1: 0,
      l2: 0,
      l3: 0,
    },
    frequency: {
      l1: 0,
      l2: 0,
      l3: 0,
    },
  });
  const [data, setData] = useState({
    current: {
      series: {
        l1: [],
        l2: [],
        l3: [],
      },
      dataCh: [0],
      math: {
        l1: { max: 0, min: 0, avg: 0, last: lastValue.current.l1 },
        l2: { max: 0, min: 0, avg: 0, last: lastValue.current.l2 },
        l3: { max: 0, min: 0, avg: 0, last: lastValue.current.l3 },
      },
    },
    power: {
      series: { l1: [], l2: [], l3: [] },
      dataCh: [0],
      math: {
        l1: { max: 0, min: 0, avg: 0, last: lastValue.power.l1 },
        l2: { max: 0, min: 0, avg: 0, last: lastValue.power.l2 },
        l3: { max: 0, min: 0, avg: 0, last: lastValue.power.l3 },
      },
    },
    powerFactor: {
      series: { l1: [], l2: [], l3: [] },
      dataCh: [0],
      math: {
        l1: { max: 0, min: 0, avg: 0, last: lastValue.powerFactor.l1 },
        l2: { max: 0, min: 0, avg: 0, last: lastValue.powerFactor.l2 },
        l3: { max: 0, min: 0, avg: 0, last: lastValue.powerFactor.l3 },
      },
    },
    voltage: {
      series: { l1: [], l2: [], l3: [] },
      dataCh: [0],
      math: {
        l1: { max: 0, min: 0, avg: 0, last: lastValue.voltage.l1 },
        l2: { max: 0, min: 0, avg: 0, last: lastValue.voltage.l2 },
        l3: { max: 0, min: 0, avg: 0, last: lastValue.voltage.l3 },
      },
    },
    energy: {
      series: { l1: [], l2: [], l3: [] },
      dataCh: [0],
      math: {
        l1: { max: 0, min: 0, avg: 0, last: lastValue.energy.l1 },
        l2: { max: 0, min: 0, avg: 0, last: lastValue.energy.l2 },
        l3: { max: 0, min: 0, avg: 0, last: lastValue.energy.l3 },
      },
    },
    frequency: {
      series: { l1: [], l2: [], l3: [] },
      dataCh: [0],
      math: {
        l1: { max: 0, min: 0, avg: 0, last: lastValue.frequency.l1 },
        l2: { max: 0, min: 0, avg: 0, last: lastValue.frequency.l2 },
        l3: { max: 0, min: 0, avg: 0, last: lastValue.frequency.l3 },
      },
    },
  });
  const params = useMemo(() => {
    return filter;
  }, [filter]);

  const getData = (param) => {
    axios
      .post(`${server()}/mg/data/airconditioner`, param, {
        headers: {
          "Content-Type": "application/json",
          widthCredentials: false,
        },
      })
      .then((res) => {
        const extractLastValue = (key) => {
          const dataSegment = res.data[key].math;
          return {
            l1: dataSegment.l1.last,
            l2: dataSegment.l2.last,
            l3: dataSegment.l3.last,
          };
        };
        const updatedData = GROUP2_VARIABLE.reduce((acc, key) => {
          acc[key] = extractLastValue(key);
          return acc;
        }, {});
        setData(res.data);
        setLastValue(updatedData);
        messageApi.success("Data Loaded Successfully.");
      })
      .catch((e) => {
        messageApi.error("Error loading the data.");
      });
  };

  const cart = [
    {
      id: 1,
      name: "Current",
      icon: <TbWaveSine />,
      lines: [
        {
          name: "Line 1",
          last: lastValue.current.l1.toFixed(1),
        },
        {
          name: "Line 2",
          last: lastValue.current.l2.toFixed(2),
        },
        {
          name: "Line 3",
          last: lastValue.current.l3.toFixed(1),
        },
      ],
      sub: data.current.math,
      unit: "A",
      dataCh: data.current.dataCh,
      series: data.current.series,
    },
    {
      id: 2,
      name: "Power",
      icon: <AiOutlineThunderbolt />,
      lines: [
        {
          name: "Line 1",
          last: lastValue.power.l1.toFixed(1),
        },
        {
          name: "Line 2",
          last: lastValue.power.l2.toFixed(1),
        },
        {
          name: "Line 3",
          last: lastValue.power.l3.toFixed(1),
        },
      ],
      sub: data.power.math,
      unit: "kW",
      dataCh: data.power.dataCh,
      series: data.power.series,
    },
    {
      id: 3,
      name: "Power Factor",
      icon: <SlEnergy />,
      lines: [
        {
          name: "Line 1",
          last: lastValue.powerFactor.l1,
        },
        {
          name: "Line 2",
          last: lastValue.powerFactor.l2,
        },
        {
          name: "Line 3",
          last: lastValue.powerFactor.l3,
        },
      ],
      sub: data.powerFactor.math,
      unit: "",
      dataCh: data.powerFactor.dataCh,
      series: data.powerFactor.series,
    },
    {
      id: 4,
      name: "Voltage",
      icon: <TbCircuitVoltmeter />,
      lines: [
        {
          name: "Line 1",
          last: lastValue.voltage.l1.toFixed(),
        },
        {
          name: "Line 2",
          last: lastValue.voltage.l2.toFixed(),
        },
        {
          name: "Line 3",
          last: lastValue.voltage.l3.toFixed(),
        },
      ],
      sub: data.voltage.math,
      unit: "V",
      dataCh: data.voltage.dataCh,
      series: data.voltage.series,
    },
    {
      id: 5,
      name: "Energy",
      icon: <TbXboxA />,
      lines: [
        {
          name: "Line 1",
          last: lastValue.energy.l1.toFixed(2),
        },
        {
          name: "Line 2",
          last: lastValue.energy.l2.toFixed(2),
        },
        {
          name: "Line 3",
          last: lastValue.energy.l3.toFixed(2),
        },
      ],
      sub: data.energy.math,
      unit: "kW",
      dataCh: data.energy.dataCh,
      series: data.energy.series,
    },
    {
      id: 6,
      name: "Frequency",
      icon: <GiLightningFrequency />,
      lines: [
        {
          name: "Line 1",
          last: lastValue.frequency.l1.toFixed(),
        },
        {
          name: "Line 2",
          last: lastValue.frequency.l2.toFixed(),
        },
        {
          name: "Line 3",
          last: lastValue.frequency.l3.toFixed(),
        },
      ],
      sub: data.frequency.math,
      unit: "Hz",
      dataCh: data.frequency.dataCh,
      series: data.frequency.series,
    },
  ];

  let question = {
    question: `Diagnostico del consumo de un aire condicionado con los siguientes valores en sus lineas:Factor de Potencia:  L1:${data.powerFactor.math.l1.last}, L2: ${data.powerFactor.math.l2.last}, L3: ${data.powerFactor.math.l3.last}, Potencia: L1: ${data.power.math.l1.last}, L2: ${data.power.math.l2.last}, L3: ${data.power.math.l3.last}, Corriente: L1: ${data.current.math.l1.last}, L2: ${data.current.math.l2.last}, L3: ${data.current.math.l3.last}, Voltaje: L1: ${data.voltage.math.l1.last},L2: ${data.voltage.math.l2.last}, L3: ${data.voltage.math.l3.last},Energia: L1: ${data.energy.math.l1.last}, L2: ${data.energy.math.l2.last}, L3: ${data.energy.math.l3.last}, Frequecia: L1: ${data.frequency.math.l1.last}, L2: ${data.frequency.math.l2.last}, L3: ${data.frequency.math.l3.last}, Revisar si existen desbalances en las lineas y que acciones correctivas se pueden tomar. limita tu respuesta a 400 caracteres`,
  };
  const getSugest = async () => {
    messageApi.open({
      type: "loading",
      content: "Getting Sugestion in progress..",
      duration: 500,
    });
    await axios
      .post(`${server()}/ia/sugestion`, JSON.stringify(question), {
        headers: {
          "Content-Type": "application/json",
          widthCredentials: false,
        },
      })
      .then((response) => {
        messageApi.success("Sugestion Loaded");
        setRsIA(response.data);
        setModal(true);
      })
      .catch((e) => {
        messageApi.close();
        messageApi.error(question);
        console.log(question);
      });
  };
  useEffect(() => {
    getData({
      id_device: "c4f412fa0142f8c5",
      date: { $gte: params.date[0], $lte: params.date[1] },
    });
  }, []);
  // useEffect(() => {
  //   const eventSource = new EventSource(
  //     `${server()}/sse/iot-events/c4f412fa0142f8c5`
  //   );
  //   eventSource.onopen = () => {
  //     console.log("Connected");
  //   };
  //   eventSource.onmessage = (event) => {
  //     let data = JSON.parse(event.data);
  //     let newData = data.airconditioner;
  //     const updatedData = GROUP2_VARIABLE.reduce((accumulator, key) => {
  //       accumulator[key] = ["L1", "L2", "L3"].reduce((obj, l) => {
  //         obj[`l${l[1]}`] = newData[key][l];
  //         return obj;
  //       }, {});
  //       return accumulator;
  //     }, {});

  //     setLastValue(updatedData);
  //     messageApi.success("Data Updated");
  //   };
  //   eventSource.onerror = (error) => {
  //     console.log("Error", error);
  //   };
  //   return () => {
  //     eventSource.close();
  //   };
  // }, []);
  return (
    <>
      <div className="layout-content">
        {contextHolder}
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          {cart.map((v, index, _) => (
            <Col
              key={index}
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={8}
              xxl={8}
              className="mb-24"
            >
              <Card bordered={true} title={v.name}>
                <Row>
                  {cart[index].lines.map((line, index) => (
                    <Col
                      key={index}
                      className="mb-24"
                      xs={8}
                      sm={8}
                      md={8}
                      lg={7}
                      xl={8}
                      xxl={8}
                    >
                      <Row>
                        <Title level={5}>
                          {v.icon}{" "}
                          {` ${line.name}: 
                            `}
                        </Title>
                      </Row>
                      <Title level={5}>
                        {`  
                         ${line.last} ${v.unit}`}
                      </Title>
                    </Col>
                  ))}
                </Row>
                <Divider />
                <Triphasic
                  dataCh={cart[index].dataCh}
                  unit={v.unit}
                  data={cart[index].sub}
                />
              </Card>
            </Col>
          ))}
        </Row>
        <FloatButton
          icon={<TbBrain />}
          onClick={() => {
            getSugest();
          }}
          tooltip="Get Suggestions from IA"
        />
        <Modal
          title="Get Suggestions from IA"
          centered
          open={modal}
          onOk={() => setModal(false)}
          onCancel={() => setModal(false)}
        >
          <p>{rsIA}</p>
        </Modal>
      </div>
    </>
  );
}
