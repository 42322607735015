import { Card, Col, Row, Select, Space } from "antd";

export default function SubStation() {
  const optionDepartamento = [
    {
      label: `Departamento 1`,
      value: `Departamento 1`,
    },
    {
      label: `Departamento 2`,
      value: `Departamento 2`,
    },
    {
      label: `Departamento 3`,
      value: `Departamento 3`,
    },
    {
      label: `Departamento 4`,
      value: `Departamento 4`,
    },
    {
      label: `Departamento 5`,
      value: `Departamento 5`,
    },
    {
      label: `Departamento 6`,
      value: `Departamento 6`,
    },
  ];
  const optionMunicipio = [
    {
      label: `Municipio 1`,
      value: `Municipio 1`,
    },
    {
      label: `Municipio 2`,
      value: `Municipio 2`,
    },
    {
      label: `Municipio 3`,
      value: `Municipio 3`,
    },
    {
      label: `Municipio 4`,
      value: `Municipio 4`,
    },
    {
      label: `Municipio 5`,
      value: `Municipio 5`,
    },
    {
      label: `Municipio 6`,
      value: `Municipio 6`,
    },
  ];
  const optionStores = [
    {
      label: `Store 1`,
      value: `Store 1`,
    },
    {
      label: `Store 2`,
      value: `Store 2`,
    },
    {
      label: `Store 3`,
      value: `Store 3`,
    },
    {
      label: `Store 4`,
      value: `Store 4`,
    },
    {
      label: `Store 5`,
      value: `Store 5`,
    },
    {
      label: `Store 6`,
      value: `Store 6`,
    },
  ];
  return (
    <>
      <div className="layout-content">
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <Card bordered={false}>
              <Space size={200}>
                <Select
                  style={{ width: 150 }}
                  options={optionDepartamento}
                  placeholder="Departamento"
                />
                <Select
                  style={{ width: 150 }}
                  options={optionMunicipio}
                  placeholder="Municipio"
                />
                <Select
                  style={{ width: 150 }}
                  options={optionStores}
                  placeholder="Tienda"
                />
              </Space>
            </Card>
          </Col>
        </Row>{" "}
      </div>
    </>
  );
}
