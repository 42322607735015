import { Layout, Typography, Button } from "antd";
import { useKeycloak } from "@react-keycloak/web";
const Content = Layout;
const Title = Typography;
export default function SinglePage() {
  const { keycloak, initialized } = useKeycloak();
  return (
    <>
      <div className="layout-default ant-layout layout-sign-up">
        <Content className="p-0">
          <div className="sign-up-header">
            <div className="content">
              <Title>Go to Single Sign-On</Title>
              <a type="primary" onClick={() => keycloak.login()}>
                <Button type="primary">Go</Button>
              </a>
            </div>
          </div>
        </Content>
      </div>
    </>
  );
}
