/**
 * Axios
 */
import axios from "axios";
/**
 * leafelt para renderizar los mapas
 */
import { Marker, MapContainer, TileLayer, Popup } from "react-leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
/**
 * Iconos
 */
import { AiFillEye } from "react-icons/ai";
import { MdStoreMallDirectory } from "react-icons/md";
import IconAra from "../../assets/images/logo-ara.svg";
import MarkerDemo from "../../assets/images/storeMark.svg";
import Ara1 from "../../assets/images/stores/AraTolu.jpg";
import Ara2 from "../../assets/images/stores/AraTolu2.jpg";
import Tienda from "../../assets/images/stores/Tienda.jpg";

// import Ara3 from "../../assets/images/stores/AraCentro.jpg";
/**
 * Componentes Antd
 */
import { Card } from "antd";

/**
 * iconos
 */

import { useKeycloak } from "@react-keycloak/web";
//import { io } from "socket.io-client";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
/**
 * Variantes
 */
const { Meta } = Card;
/**
 * Funcion General de la vista Home
 * @returns
 */
export default function Home() {
  const [source, setSource] = useState(null);
  const { keycloak, inicialiced } = useKeycloak();
  axios.defaults.headers.common["Authorization"] = `Bearer ${keycloak.token}`;
  let mark;
  if (keycloak.hasResourceRole("super-admin")) {
    mark = IconAra;
  } else if (keycloak.hasResourceRole("admin")) {
    mark = MarkerDemo;
  } else if (keycloak.hasResourceRole("viewer")) {
    mark = IconAra;
  } else if (keycloak.hasResourceRole("demo")) {
    mark = MarkerDemo;
  }
  /**
   * Array para renderizar los marcadores del mapa
   */
  const markers = [
    {
      id: "a001",
      name: "Tienda Tolu #1",
      description: "Calle 15 # 2-06, Santiago de Tolú",
      image: Tienda,
      location: [9.5235815, -75.5848516],
    },
    {
      id: "a002",
      name: "Tienda Tolu #2",
      description: "Cra. 9 #16 70 1, Santiago de Tolú, Sucre",
      image: Tienda,
      location: [9.5223737, -75.5788892],
    },
    // {
    //   id: "a003",
    //   name: "Ara Tolu",
    //   description: "Cra. 2 #14-92, Santiago de Tolú, Sucre",
    //   image: Ara3,
    //   location: [9.5294363, -75.5817418],
    // },
  ];
  /**
   * Icono de los marcadores del mapa
   */
  const iconsAraMAp = L.icon({
    iconUrl: mark,
    iconSize: [50, 50],
    iconAnchor: [19, 38],
    popupAnchor: [0, -38],
  });

  


  return (
    <>
      <div className="layout-content">
        {/**Mapa */}
        <div>
          <Card className="mb-24" bordered={true} title="Mapa">
            <MapContainer
              center={markers[0].location}
              zoom={15}
              scrollWheelZoom={false}
              style={{ height: "500px", width: "100%" }}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {markers.map((marker) => (
                <Marker
                  key={marker.id}
                  position={marker.location}
                  icon={iconsAraMAp}
                >
                  <Popup>
                    <Card
                      title={marker.name}
                      style={{
                        width: 150,
                      }}
                      cover={<img alt="example" src={marker.image} />}
                      actions={[
                        <Link to="/device" className="nav-link">
                          <AiFillEye key="Show Store" size={16} />
                        </Link>,
                      ]}
                    >
                      <Meta description={marker.description}></Meta>
                    </Card>
                  </Popup>
                </Marker>
              ))}
            </MapContainer>
          </Card>
        </div>
      </div>
    </>
  );
}
