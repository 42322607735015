import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import ARALOGO from "../../assets/images/logo-ara.svg";
import IDT from "../../assets/images/idtolu.png";
import DEMO from "../../assets/images/Emtiot.png";
import SIC from "../../assets/images/logo.png";
import { FcHighPriority } from "react-icons/fc";
import "./bouncingLogo.css";
import { Alert, Avatar, Button, Card, Col, Row } from "antd";
import Meta from "antd/es/card/Meta";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { FaEye } from "react-icons/fa";
import { useKeycloak } from "@react-keycloak/web";

export default function BouncingLogo() {
  const { keycloak, inicialiced } = useKeycloak();
  let logo;
  if (keycloak.hasResourceRole("super-admin")) {
    logo = IDT;
  } else if (keycloak.hasResourceRole("admin")) {
    logo = SIC;
  } else if (keycloak.hasResourceRole("viewer")) {
    logo = SIC;
  } else if (keycloak.hasResourceRole("demo")) {
    logo = DEMO;
  }
  const alert = [
    {
      id: "03ng204fv",
      nameDevice: " Transfer Board",
      status: "error",
      date: "10/05/2023",
      mesagu: "error to Transfer Board ",
    },
    {
      id: "034634fv",
      nameDevice: "Light Controller ",
      status: "error",
      date: "10/05/2023",
      mesagu: "Light Controller ",
    },
    {
      id: "03nfv",
      nameDevice: " Air Conditioner",
      status: "error",
      date: "10/05/2023",
      mesagu: "error to air condition",
    },
    {
      id: "03nfefv",
      nameDevice: "Power Plant ",
      status: "error",
      date: "10/05/2023",
      mesagu: "error to Power Plant",
    },
  ];
  const [isActive, setIsActive] = useState(false);

  setInterval(() => {
    setIsActive(!isActive);
  }, 10000);

  return (
    <>
      {isActive ? (
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          {alert.map((a, index, _) => (
            <Col key={index}>
              <Card
                style={{ background: "#fafafa" }}
                actions={[<FaEye key="setting" />]}
              >
                <Meta
                  title={a.nameDevice}
                  avatar={<Avatar src={<FcHighPriority size={30} />} />}
                  description={a.mesagu}
                />
                <span>{a.date}</span>
              </Card>
            </Col>
          ))}
        </Row>
      ) : (
        <motion.div
          // animate={{ rotate: 0, x: 100, y: 100 }}
          drag
          dragConstraints={{ top: -100, left: -100, right: 100, bottom: 100 }}
          transition={{ ease: "easeOut", duration: 2 }}
          whileHover={{ scale: [null, 1.5, 1.4] }}
          animate={{
            scale: [1, 2, 2, 1, 1],
            rotate: [0, 90, 180, 270, 360],
            borderRadius: ["20%", "20%", "50%", "50%", "20%"],
          }}
          className="bouncing-logo"
        >
          <img src={logo} alt="Ara-Logo" />
        </motion.div>
      )}
    </>
  );
}
