/**
 * Dependency
 */
import { Card, Col, Row, Space, Table, Typography, message } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
//import moment from "moment";
import axios from "axios";
/**
 * Context
 */
import { controlPanelContext } from "../../context/controlPanel";
import { ReportCsv } from "../../api/service/report/reportCsv";
import { ReportExcel } from "../../api/service/report/reportExcel";
/**
 * Modules
 */

import { server } from "../../api/axios";
import moment from "moment";

const { Title } = Typography;
/**
 * Columnas de la tabla
 */
const generateColumns = (type) => {
  const baseKeys = ["L1", "L2", "L3"];
  return {
    title: type.toUpperCase(),
    dataIndex: type.toLowerCase(),
    key: type.toLowerCase(),
    children: baseKeys.map((key) => ({
      title: `Line ${key[1]}`,
      dataIndex: `${key}${type}`,
      key: `${key}${type}`,
      width: 100,
    })),
  };
};
// red electrica
// const dataTypes = [
//   "current",
//   "power",
//   "powerFactor",
//   "reactivePower",
//   "voltage",
//   "voltAmpere",
//   "VLL",
//   "harmonicI",
//   "harmonicV",
//   "frequency",
// ];

// pzem
const dataTypes = [
  "current",
  "power",
  "powerFactor",
  "voltage",
  "energy",
  "frequency",
];

// const dataTypes = [
//   "temperature",
//   "humidity",
// ];

const columns = [
  {
    title: "DATE",
    dataIndex: "date",
    key: "date",
    width: 100,
  },
  {
    title: "DEVICE",
    dataIndex: "name",
    key: "name",
    width: 150,
  },
  ...dataTypes.map((type) => generateColumns(type)),
];

// const columns = [
//   {
//     title: "DATE",
//     dataIndex: "date",
//     key: "date",
//     width: 100,
//   },
//   {
//     title: "DEVICE",
//     dataIndex: "name",
//     key: "name",
//     width: 100,
//   },
//   {
//     title: "Temperature",
//     dataIndex: "temperature",
//     key: "temperature",
//     width: 100,
//   },
//   {
//     title: "Humidity",
//     dataIndex: "humidity",
//     key: "humidity",
//     width: 100,
//   }

// ];

// neveras
// const columns = [
//   {
//     title: "DATE",
//     dataIndex: "date",
//     key: "date",
//     width: 100,
//   },
//   {
//     title: "DEVICE",
//     dataIndex: "name",
//     key: "name",
//     width: 100,
//   },
//   {
//     title: "Temperature",
//     dataIndex: "temperature",
//     key: "temperature",
//     width: 100,
//   },

// ];

export default function Report() {
  const { keycloak, inicialiced } = useKeycloak();
  axios.defaults.headers.common["Authorization"] = `Bearer ${keycloak.token}`;
  const [messageApi, contextHolder] = message.useMessage();
  const [data, setData] = useState([]);
  const [filter, setFilter] = useContext(controlPanelContext);
  const params = useMemo(() => {
    return filter;
  }, [filter]);
  const getData = (param) => {
    axios
      .post(`${server()}/mg/data/report`, param, {
        headers: {
          "Content-Type": "application/json",
          widthCredentials: false,
        },
      })
      .then((response) => {
        messageApi.success("Data Loaded Successfully.");

        setData(response.data);
      })
      .catch((e) => {
        messageApi.error("Error loading the data.");
      });
  };
  useEffect(() => {
    getData({
      id_device: "c4f412fa0142f8c5",
      date: { $gte: params.date[0], $lte: params.date[1] },
    });
  }, [setFilter]);
  // useEffect(() => {
  //   getData({
  //     id_device: "c4f412fa437be4c5",
  //     date: {
  //       $gte: moment().subtract(32, "day").valueOf(),
  //       $lte: moment().valueOf(),
  //     },
  //   });
  // }, [setFilter]);

  return (
    <>
      <div className="tabled">
        {contextHolder}
        <Row gutter={[24, 0]}>
          <Col xs={24} xl={24}>
            <Card
              bordered={true}
              className="criclebox tablespace mb-24"
              title="Generate Report"
            >
              <div className="table-responsive">
                <Row>
                  <Space size={50} align="end">
                    {/* <Select
                      style={{ width: 150 }}
                      mode="multiple"
                      options={optionDevices}
                      allowClear
                      placeholder="Choose Devices"
                    />
                    <Select
                      style={{ width: 150 }}
                      mode="multiple"
                      options={optionVariable}
                      allowClear
                      placeholder="Choose Variable"
                    /> */}
                    <ReportExcel dataSet={data} />
                    <ReportCsv dataSet={data} fileName="Report" />
                  </Space>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card bordered={true}>
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={data}
                  pagination={true}
                  className="ant-border-space"
                  bordered
                  size="middle"
                  scroll={{
                    x: "calc(700px + 50%)",
                  }}
                ></Table>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
