const SERIES_3_KEYS = ["l1", "l2", "l3"];
const GROUP1_VARIABLE = [
  "VLL",
  "current",
  "power",
  "powerFactor",
  "reactivePower",
  "voltage",
  "voltAmpere",
  "harmonicV",
  "harmonicI",
  "frequency",
];
const GROUP2_VARIABLE = [
  "current",
  "power",
  "powerFactor",
  "voltage",
  "energy",
  "frequency",
];
const DATA_STRUCTURE_LAST_3 = {
  VLL: {},
  current: {},
  power: {},
  powerFactor: {},
  reactivePower: {},
  voltage: {},
  voltAmpere: {},
  harmonicV: {},
  harmonicI: {},
  frequency: {},
};
export {
  SERIES_3_KEYS,
  DATA_STRUCTURE_LAST_3,
  GROUP1_VARIABLE,
  GROUP2_VARIABLE,
};
