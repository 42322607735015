import { Avatar, Button, Card, Col, Row, Table, Typography } from "antd";
import axios from "axios";
import { useContext, useEffect, useMemo, useState } from "react";
import { server } from "../../api/axios";
import { useKeycloak } from "@react-keycloak/web";
import { controlPanelContext } from "../../context/controlPanel";

const { Title } = Typography;
export default function BlockChain() {
  const { keycloak, inicialiced } = useKeycloak();
  axios.defaults.headers.common["Authorization"] = `Bearer ${keycloak.token}`;
  const [data, setData] = useState([]);
  const [filter, setFilter] = useContext(controlPanelContext);
  const params = useMemo(() => {
    return filter;
  }, [filter]);

  /**
   * Columnas de la Tabla
   */
  const columns = [
    {
      title: "DATE",
      dataIndex: "date",
      key: "date",
      width: 100,
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "SMART CONTRACT",
      dataIndex: "smartContract",
      key: "smartContract",
    },
    {
      title: "DEVICE",
      dataIndex: "name",
      key: "name",
      width: 150,
    },
    {
      title: "Current",
      dataIndex: "current",
      children: [
        {
          title: "Line 1",
          dataIndex: "L1current",
          key: "L1current",
          width: 100,
          //sorter: (a, b) => a.age - b.age,
        },
        {
          title: "Line 2",
          dataIndex: "L2current",
          key: "L2current",
          width: 100,
          //sorter: (a, b) => a.age - b.age,
        },
        {
          title: "Line 3",
          dataIndex: "L3current",
          key: "L3current",
          width: 100,
          //sorter: (a, b) => a.age - b.age,
        },
      ],
    },
    {
      title: "Power",
      children: [
        {
          title: "Line 1",
          dataIndex: "L1power",
          key: "L1power",
          width: 100,
          //sorter: (a, b) => a.age - b.age,
        },
        {
          title: "Line 2",
          dataIndex: "L2power",
          key: "L2power",
          width: 100,
          //sorter: (a, b) => a.age - b.age,
        },
        {
          title: "Line 3",
          dataIndex: "L3power",
          key: "L3power",
          width: 100,
          //sorter: (a, b) => a.age - b.age,
        },
      ],
    },
    {
      title: "Factor Power",
      children: [
        {
          title: "Line 1",
          dataIndex: "L1powerFactor",
          key: "L1current",
          width: 100,
          //sorter: (a, b) => a.age - b.age,
        },
        {
          title: "Line 2",
          dataIndex: "L2powerFactor",
          key: "L2powerFactor",
          width: 100,
          //sorter: (a, b) => a.age - b.age,
        },
        {
          title: "Line 3",
          dataIndex: "L3powerFactor",
          key: "L3powerFactor",
          width: 100,
          //sorter: (a, b) => a.age - b.age,
        },
      ],
    },
    {
      title: "Voltage",
      children: [
        {
          title: "Line 1",
          dataIndex: "L1voltage",
          key: "L1voltage",
          width: 100,
          //sorter: (a, b) => a.age - b.age,
        },
        {
          title: "Line 2",
          dataIndex: "L2voltage",
          key: "L2voltage",
          width: 100,
          //sorter: (a, b) => a.age - b.age,
        },
        {
          title: "Line 3",
          dataIndex: "L3voltage",
          key: "L3voltage",
          width: 100,
          //sorter: (a, b) => a.age - b.age,
        },
      ],
    },

    {
      title: "Energy",
      children: [
        {
          title: "Line 1",
          dataIndex: "L1energy",
          key: "L1energy",
          width: 100,
          //sorter: (a, b) => a.age - b.age,
        },
        {
          title: "Line 2",
          dataIndex: "L2energy",
          key: "L2energy",
          width: 100,
          //sorter: (a, b) => a.age - b.age,
        },
        {
          title: "Line 3",
          dataIndex: "L3energy",
          key: "L3energy",
          width: 100,
          //sorter: (a, b) => a.age - b.age,
        },
      ],
    },
    {
      title: "Frequency",
      children: [
        {
          title: "Line 1",
          dataIndex: "L1frequency",
          key: "L1frequency",
          width: 100,
          //sorter: (a, b) => a.age - b.age,
        },
        {
          title: "Line 2",
          dataIndex: "L2frequency",
          key: "L2frequency",
          width: 100,
          //sorter: (a, b) => a.age - b.age,
        },
        {
          title: "Line 2",
          dataIndex: "L3frequency",
          key: "L3frequency",
          width: 100,
          //sorter: (a, b) => a.age - b.age,
        },
      ],
    },
  ];
  // table code start

  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };
  const getData = (param) => {
    axios
      .post(`${server()}/mg/data/report`, param, {
        headers: {
          "Content-Type": "application/json",
          widthCredentials: false,
        },
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  data.forEach((item) => {
    item.status = (
      <>
        <Button type="primary">Verified</Button>
      </>
    );
    item.smartContract = "0xf65a3b65aacc41d2b89abd645f82fd02c9a6d8cd";
  });

  useEffect(() => {
    getData({
      id_device: "c4f412fa0142f8c5",
      date: { $gte: params.date[0], $lte: params.date[1] },
    });
  }, []);
  return (
    <>
      <div className="tabled">
        <Row>
          <Col xs="24" xl={24}>
            <Card
              bordered={true}
              className="criclebox tablespace mb-24"
              title="Information Validation"
            >
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={data}
                  pagination={true}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
