/**
 * Dependencys
 */

import React, { useEffect, useState } from "react";
import axios from "axios";

import moment from "moment";
import { useKeycloak } from "@react-keycloak/web";
/**
 * Components
 */
import { Button, Card, Col, Form, Input, Row, message } from "antd";
import { server } from "../../api/axios";

/**
 * icons
 */

/**
 * constants
 */

export default function Setting() {
  const { keycloak, inicialized } = useKeycloak();
  axios.defaults.headers.common["Authorization"] = `Bearer ${keycloak.token}`;
  const [messageApi, contextHolder] = message.useMessage();
  const [valuesForm, setValuesForm] = useState({
    name: " ",
    updateAt: "",
    channel: [
      { name: "email", email: ["evergarah2000@gmail.com"] },
      { name: "whatsApp", phones: ["573017779929", "573017828735"] },
    ],
  });
  const CreateSettings = async () => {
    await axios
      .post(`${server()}/mg/config`, { org: 6, id: 6 })
      .then((res) => {
        alert(JSON.stringify(res.data));
      })
      .catch((e) => {
        alert(e);
      });
  };
  const getConfig = async () => {
    await axios
      .get(`${server()}/mg/config?id=6`)
      .then((res) => {
        setValuesForm(res.data[0]);
      })
      .catch((e) => {
        alert(e);
      });
  };
  const UpdateCofig = async (update) => {
    await axios
      .put(`${server()}/mg/config`, update)
      .then((res) => {
        messageApi.success("Configuration updated successfully.");
      })
      .catch((e) => {
        messageApi.error("Error updating configuration.");
      });
  };

  function validateEmail(emails) {
    const emailString = emails.split(",");
    const regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;

    emailString.forEach((element) => {
      if (!regex.test(element.trim())) {
        return false;
      }
    });
    return true;
  }

  const onFinish = (values) => {
    let update = {
      id: 6,
      data: {
        name: values.name,
        channel: [
          { name: "email", email: values.emails.split(",") },
          { name: "whatsApp", phones: values.phones.split(",") },
        ],
      },
    };
    UpdateCofig(update);
  };

  useEffect(() => {
    //CreateSettings();
    getConfig();
    //UpdateCofig();
  }, []);
  return (
    <>
      <div>
        {contextHolder}
        <Card title="Setting">
          <Form onFinish={onFinish}>
            <div>
              <Row>
                <Col
                  className="mb-24"
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                >
                  <Form.Item
                    label="Store Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please input Name Store",
                      },
                    ]}
                  >
                    <Input placeholder={valuesForm.name} />
                  </Form.Item>
                </Col>
                <Col
                  className="mb-24"
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                >
                  <Form.Item label="UpdateAt:" name="updatedAt">
                    <Input
                      placeholder={moment(valuesForm.updateAt).format(
                        "DD/MM/YYYY"
                      )}
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col
                  className="mb-24"
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                >
                  <Form.Item
                    label="Number Phones"
                    name="phones"
                    rules={[
                      {
                        required: true,
                        message:
                          "Please enter the telephone numbers with the country code separated by commas: 573015123529, ",
                      },
                    ]}
                  >
                    <Input
                      placeholder={valuesForm.channel[1].phones.toLocaleString()}
                    />
                  </Form.Item>
                </Col>
                <Col
                  className="mb-24"
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                >
                  <Form.Item
                    label="Emails:"
                    name="emails"

                    // rules={[
                    //   {
                    //     required: true,
                    //     message:
                    //       "Please enter the email addresses  separated by commas: evxxxxxxx@gmail.com",
                    //     validator: (_, value) => {
                    //       validateEmail(value)
                    //         ? Promise.resolve()
                    //         : Promise.reject(
                    //             new Error(
                    //               "Please enter the email addresses  separated by commas: evxxxxxxx@gmail.com"
                    //             )
                    //           );
                    //     },
                    //   },
                    // ]}
                  >
                    <Input
                      placeholder={valuesForm.channel[0].email.toLocaleString()}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col
                  className="mb-24"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Form>
        </Card>
      </div>
    </>
  );
}
