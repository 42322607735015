import { useKeycloak } from "@react-keycloak/web";
/**
 * Router Dom
 */
import { Route, Routes } from "react-router-dom";
/**
 * Componente Padre
 */
import { Main } from "../components/layouts/Main.js";
/** Estatus */
import NotFound from "../view/status/noFound";
import Warning from "../view/status/warning";
import UnAuthorized from "../view/status/unathorized";
/**Vistas */
import Home from "../view/users/home.jsx";
import Device from "../view/device/device.jsx";

import Profile from "../view/users/profile.jsx";
import Setting from "../view/users/setting.jsx";
import Report from "../view/users/report.jsx";
import BlackScreen from "../components/blackScreen/blackScreen.jsx";
import History from "../view/users/history.jsx";
import BlockChain from "../view/users/blockchain.jsx";
/**
 * Vista de los Dispositivos
 */
import Refrigerator from "../view/device/refrigerator/refrigerator.jsx";
import Electrical from "../view/device/electricalNetwork/electricalnetwork.jsx";
import SubStation from "../view/device/substation/substation.jsx";
//import TransferBoard from "../view/device/transferBoard/transferBoard.jsx";
//import Ups from "../view/device/ups/ups.jsx";
import Air from "../view/device/airCondinitioner/airConditioner.jsx";
//import PowerPlant from "../view/device/powerPlant/powerPlant.jsx";
import Light from "../view/device/light/light.jsx";

import { Layout, Spin } from "antd";
import SinglePage from "../view/public/singlePage.jsx";
import AirCurtain from "../view/device/airCurtain/airCurtain.jsx";
import Thermostat from "../view/device/thermostat/thermostat.jsx";

const Content = Layout;
const contentStyle = {
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
  minHeight: "800px",
  alignItems: "center",
  lineHeight: "120px",
  color: "#fff",
};
/**
 * Funcion authroutes
 * @returns
 */
/**
 * Configuracion con proveedor de identidad
 */
export default function AuthRoutes() {
  const { keycloak, initialized } = useKeycloak();
  if (!initialized) {
    return (
      <Layout>
        <Content style={contentStyle}>
          <Spin tip="Loading" size="large" />
        </Content>
      </Layout>
    );
  }
  /**
   * Rutas Super Admin
   */
  if (keycloak.hasResourceRole("super-admin")) {
    return (
      <Main>
        <Routes>
          {/**
           * Vistas
           */}
          <Route path="history" element={<History />} />
          <Route path="report" element={<Report />} />
          <Route path="/" element={<Home />} />
          <Route path="profile" element={<Profile />} />
          <Route path="notification" element={<Setting />} />
          <Route path="black" element={<BlackScreen />}></Route>
          <Route path="/block" element={<BlockChain />}></Route>
          {/**
           * Rutas de los Dispositivos
           */}
          <Route path="device" element={<Device />} />
          {/**
           * Categorias
           */}
          <Route path="device/thermostat" element={<Thermostat />} />
          <Route path="device/refrigerator" element={<Refrigerator />} />
          <Route path="device/electrical" element={<Electrical />} />
          <Route path="device/substation" element={<SubStation />} />
          {/* <Route
            path="device/transferboard"
            element={<TransferBoard />}
          ></Route>
          <Route path="device/ups" element={<Ups />}></Route> */}
          <Route path="device/airconditioner" element={<Air />}></Route>
          {/* <Route path="device/powerplant" element={<PowerPlant />}></Route> */}
          <Route path="device/lights" element={<Light />}></Route>
          <Route path="device/aircurtain" element={<AirCurtain />}></Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Main>
    );
  }
  /**
   * Rutas Admin
   */
  if (keycloak.hasResourceRole("admin") || keycloak.hasResourceRole("demo")) {
    return (
      <Main>
        <Routes>
          {/**
           * Vistas
           */}
          <Route path="history" element={<History />} />
          <Route path="report" element={<Report />} />
          <Route path="/" element={<Home />} />
          <Route path="profile" element={<Profile />} />
          <Route path="notification" element={<Setting />} />
          <Route path="black" element={<BlackScreen />}></Route>
          <Route path="/block" element={<BlockChain />}></Route>{" "}
          <Route path="setting" element={<Setting />} />
          {/**
           * Rutas de los Dispositivos
           */}
          <Route path="device" element={<Device />} />
          {/**
           * Categorias
           */}
          <Route path="device/thermostat" element={<Thermostat />} />
          <Route path="device/refrigerator" element={<Refrigerator />} />
          <Route path="device/electrical" element={<Electrical />} />
          <Route path="device/substation" element={<SubStation />} />
          {/* <Route
            path="device/transferboard"
            element={<TransferBoard />}
          ></Route>
          <Route path="device/ups" element={<Ups />}></Route> */}
          <Route path="device/airconditioner" element={<Air />}></Route>
          {/* <Route path="device/powerplant" element={<PowerPlant />}></Route> */}
          <Route path="device/lights" element={<Light />}></Route>
          <Route path="device/aircurtain" element={<AirCurtain />}></Route>
          {/**Ruta no Found */}
          <Route path="*" element={<NotFound />} />
          {/* <Route path="device/thermostat" element={<Thermostat />} />
          <Route path="device/substation" element={<SubStation />} />
          <Route
            path="device/transferboard"
            element={<TransferBoard />}
          ></Route>
          <Route path="device/ups" element={<Ups />}></Route>
          <Route path="device/airconditioner" element={<Air />}></Route>
          <Route path="device/powerplant" element={<PowerPlant />}></Route>
          <Route path="device/lightcontroller" element={<Light />}></Route> */}
        </Routes>
      </Main>
    );
  }
  /**
   * Rutas Viewer
   */
  if (keycloak.hasResourceRole("viewer")) {
    return (
      <Main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="profile" element={<Profile />} />
          <Route path="notification" element={<Setting />} />
          <Route path="black" element={<BlackScreen />}></Route>
          <Route path="/block" element={<BlockChain />}></Route>

          {/**
           * Rutas de los Dispositivos
           */}
          <Route path="device" element={<Device />} />
          {/**
           * Categorias
           */}
          <Route path="device/thermostat" element={<Thermostat />} />
          <Route path="device/refrigerator" element={<Refrigerator />} />
          <Route path="device/electrical" element={<Electrical />} />
          {/* <Route path="device/substation" element={<SubStation />} /> */}
          {/* <Route
            path="device/transferboard"
            element={<TransferBoard />}
          ></Route>
          <Route path="device/ups" element={<Ups />}></Route> */}
          <Route path="device/airconditioner" element={<Air />}></Route>
          {/* <Route path="device/powerplant" element={<PowerPlant />}></Route> */}
          <Route path="device/lights" element={<Light />}></Route>
          <Route path="device/aircurtain" element={<AirCurtain />}></Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Main>
    );
  }
  /**
   * Vista por defecto
   */
  return (
    <Routes>
      <Route path="/" element={<SinglePage />} />
      <Route path="*" element={<UnAuthorized />} />
    </Routes>
  );
}
