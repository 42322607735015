import axios from "axios";
import { useContext, useEffect, useMemo, useState } from "react";
import { server } from "../../../api/axios";
import { useKeycloak } from "@react-keycloak/web";
import {
  Card,
  Col,
  Divider,
  FloatButton,
  Modal,
  Row,
  message,
  Typography,
} from "antd";
import { GiLightningFrequency } from "react-icons/gi";
import {
  TbBrain,
  TbCircuitVoltmeter,
  TbWaveSine,
  TbXboxA,
} from "react-icons/tb";
import { SlEnergy } from "react-icons/sl";
import { AiOutlineThunderbolt } from "react-icons/ai";
import SinglePhase from "../partialView/singlePhaseEquipment/singlePhase";
import { controlPanelContext } from "../../../context/controlPanel";
const { Title } = Typography;
export default function AirCurtain() {
  const { keycloak, inicialiced } = useKeycloak();
  axios.defaults.headers.common["Authorization"] = `Bearer ${keycloak.token}`;
  const [filter, setFilter] = useContext(controlPanelContext);
  const [messageApi, contextHolder] = message.useMessage();
  const [rsIA, setRsIA] = useState("Ups Try Again Later");
  const [modal, setModal] = useState(false);
  const [data, setData] = useState({
    current: {
      series: { l1: [0] },
      dataCh: [0],
      math: {
        l1: { max: 0, min: 0, avg: 0, last: 0 },
      },
    },
    power: {
      series: { l1: [0] },
      dataCh: [0],
      math: {
        l1: { max: 0, min: 0, avg: 0, last: 0 },
      },
    },
    powerFactor: {
      series: { l1: [0] },
      dataCh: [0],
      math: {
        l1: { max: 0, min: 0, avg: 0, last: 0 },
      },
    },
    voltage: {
      series: { l1: [0] },
      dataCh: [0],
      math: {
        l1: { max: 0, min: 0, avg: 0, last: 0 },
      },
    },
    energy: {
      series: { l1: [0] },
      dataCh: [0],
      math: {
        l1: { max: 0, min: 0, avg: 0, last: 0 },
      },
    },
    frequency: {
      series: { l1: [0] },
      dataCh: [0],
      math: {
        l1: { max: 0, min: 0, avg: 0, last: 0 },
      },
    },
    categories: [],
  });
  const params = useMemo(() => {
    return filter;
  }, [filter]);
  const getData = (param) => {
    axios
      .post(`${server()}/mg/data/aircurtain`, param, {
        headers: {
          "Content-Type": "application/json",
          widthCredentials: false,
        },
      })
      .then((res) => {
        messageApi.success("Data Loaded Successfully.");
        setData(res.data);
      })
      .catch((e) => {
        messageApi.error("Error loading the data.");
      });
  };
  useEffect(() => {
    getData({
      id_device: "c4f412fa0142f8c5",
      date: { $gte: params.date[0], $lte: params.date[1] },
    });
  }, []);
  const cart = [
    {
      id: 1,
      name: "Current",
      icon: <TbWaveSine />,
      lines: [
        {
          name: "Line 1",
          last: data.current.math.l1.last.toFixed(2),
        },
      ],
      sub: data.current.math,
      dataCh: data.current.dataCh,
      unit: "A",
    },
    {
      id: 2,
      name: "Power",
      icon: <AiOutlineThunderbolt />,
      lines: [
        {
          name: "Line 1",
          last: data.power.math.l1.last.toFixed(2),
        },
      ],
      sub: data.power.math,
      dataCh: data.power.dataCh,
      unit: "kW",
    },
    {
      id: 3,
      name: "Power Factor",
      icon: <SlEnergy />,
      lines: [
        {
          name: "Line 1",
          last: data.powerFactor.math.l1.last,
        },
      ],
      sub: data.powerFactor.math,
      dataCh: data.powerFactor.dataCh,
      unit: "",
    },
    {
      id: 4,
      name: "Voltage",
      icon: <TbCircuitVoltmeter />,
      lines: [
        {
          name: "Line 1",
          last: data.voltage.math.l1.last.toFixed(2),
        },
      ],
      sub: data.voltage.math,
      dataCh: data.voltage.dataCh,
      unit: "V",
    },
    {
      id: 5,
      name: "Energy",
      icon: <TbXboxA />,
      lines: [
        {
          name: "Line 1",
          last: data.energy.math.l1.last.toFixed(2),
        },
      ],
      sub: data.energy.math,
      dataCh: data.energy.dataCh,
      unit: "kW",
    },
    {
      id: 6,
      name: "Frequency",
      icon: <GiLightningFrequency />,
      lines: [
        {
          name: "Line 1",
          last: data.frequency.math.l1.last.toFixed(2),
        },
      ],
      sub: data.frequency.math,
      dataCh: data.frequency.dataCh,
      unit: "Hz",
    },
  ];

  let question = {
    question: `Diagnostico del consumo de una Cortina de aire con los siguientes valores en sus lineas:Factor de Potencia:  L1:${data.powerFactor.math.l1.last}, Potencia: L1: ${data.power.math.l1.last}, Corriente: L1: ${data.current.math.l1.last}, Voltaje: L1: ${data.voltage.math.l1.last}, Energia: L1: ${data.energy.math.l1.last},Frecuencia: L1: ${data.frequency.math.l1.last}, Revisar si existen desbalances en las lineas y que acciones correctivas se pueden tomar. limita tu respuesta a 400 caracteres`,
  };
  const getSugest = async () => {
    messageApi.open({
      type: "loading",
      content: "Getting Suggestion in progress..",
      duration: 500,
    });
    await axios
      .post(`${server()}/ia/sugestion`, JSON.stringify(question), {
        headers: {
          "Content-Type": "application/json",
          widthCredentials: false,
        },
      })
      .then((response) => {
        messageApi.success("Suggestion Loaded");
        setRsIA(response.data);
        setModal(true);
      })
      .catch((e) => {
        messageApi.close();
        messageApi.error(question);
        console.log(question);
      });
  };
  return (
    <>
      <div className="layout-content">
        {contextHolder}
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          {cart.map((v, index, _) => (
            <Col
              key={index}
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={8}
              xxl={8}
              className="mb-24"
            >
              <Card bordered={true} title={v.name}>
                <Row>
                  {cart[index].lines.map((line, index) => (
                    <Col
                      key={index}
                      className="mb-24"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                      xxl={24}
                    >
                      <Title level={4}>
                        {v.icon}{" "}
                        {` ${line.name}: 
                         ${line.last} ${v.unit}`}
                      </Title>
                    </Col>
                  ))}
                </Row>
                <Divider />
                <SinglePhase
                  unit={v.unit}
                  data={cart[index].sub}
                  dataCh={cart[index].dataCh}
                />
              </Card>
            </Col>
          ))}
        </Row>
        <FloatButton
          icon={<TbBrain />}
          onClick={() => {
            getSugest();
          }}
          tooltip="Get Suggestions from IA"
        />
        <Modal
          title="Get Suggestions from IA"
          centered
          open={modal}
          onOk={() => setModal(false)}
          onCancel={() => setModal(false)}
        >
          <p>{rsIA}</p>
        </Modal>
      </div>
    </>
  );
}
