import { Layout, Menu, theme, Affix, Breadcrumb, Drawer } from "antd";
import React, { useEffect, useState } from "react";
import SideNav from "./Sidenav.jsx";
import Footer from "./Footer.jsx";
import Header from "./Header.jsx";
import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import ControlPanel from "../controlPanel/controlPanel.jsx";
import ControlPanelProvider from "../../context/controlPanel.js";
const { Header: AntHeader, Sider, Content } = Layout;
/**
 *
 * @param {definicion de la estructura globla recibe por props el content}
 * @returns
 */
export function Main({ children }) {
  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("right");
  const [sidenavColor, setSidenavColor] = useState("#FA8C0B");
  const [sidenavType, setSidenavType] = useState("transparent");
  const [fixed, setFixed] = useState(false);
  const openDrawer = () => setVisible(!visible);
  const handleSidenavType = (type) => setSidenavType(type);
  const handleSidenavColor = (color) => setSidenavColor(color);
  const handleFixedNavbar = (type) => setFixed(type);

  let { pathname } = useLocation();
  pathname = pathname.replace("/", " ");

  const [location, setLocation] = useState("");

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setLocation();
  }, []);

  return (
    <Layout className={`layout-dashboard `}>
      <Drawer
        title={false}
        placement={placement === "right" ? "left" : "right"}
        closable={false}
        onClose={onClose}
        open={open}
        key={placement === "right" ? "left" : "right"}
        width={250}
        className={`drawer-sidebar  `}
      >
        <Layout
          className={`layout-dashboard ${
            pathname === "rtl" ? "layout-dashboard-rtl" : ""
          }`}
        >
          <Sider
            trigger={null}
            width={250}
            theme="light"
            className={`sider-primary ant-layout-sider-primary active-route`}
            style={{ background: sidenavType }}
          >
            <SideNav color={sidenavColor} />
          </Sider>
        </Layout>
      </Drawer>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
        trigger={null}
        width={250}
        theme="light"
        className={`sider-primary ant-layout-sider-primary ${
          sidenavType === "#fff" ? "active-route" : ""
        }`}
        style={{ background: sidenavType }}
      >
        <SideNav color={sidenavColor} />
      </Sider>
      <Layout>
        {fixed ? (
          <Affix>
            <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
              <Header
                name={pathname}
                subName={pathname}
                handleFixedNavbar={handleFixedNavbar}
              />
            </AntHeader>
          </Affix>
        ) : (
          <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
            <Header
              onPress={showDrawer}
              name={pathname}
              subName={pathname}
              handleFixedNavbar={handleFixedNavbar}
            />
          </AntHeader>
        )}
        {/**Contexto Global del Control Panel */}
        <ControlPanelProvider>
          {/**
           * Hijo del Contex
           */}
          <ControlPanel />
          <Content>{children}</Content>
        </ControlPanelProvider>
        <Footer />
      </Layout>
    </Layout>
  );
}
