/**
 * Otros Componentes
 */

/**
 * Iconos
 */
import { CiTempHigh } from "react-icons/ci";
/**
 * Componentes Antd
 */
import { Card, Col, Divider, Row, message, Typography } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import { controlPanelContext } from "../../../context/controlPanel";
import axios from "axios";
import { server } from "../../../api/axios.js";
import { useKeycloak } from "@react-keycloak/web";
import { Line } from "@ant-design/plots";
/**
 * Variaciones
 */
const { Meta } = Card;

const { Title } = Typography;
export default function Refrigerator() {
  const { keycloak, inicialiced } = useKeycloak();
  axios.defaults.headers.common["Authorization"] = `Bearer ${keycloak.token}`;
  /**
   * definicion del uso del context del padre
   */
  const [filter, setFilter] = useContext(controlPanelContext);
  /**
   * Hook useMemo para guardar en cache los valores del context
   * Se ejecuata cuando Filter tiene un valor
   */
  const params = useMemo(() => {
    return filter;
  }, [filter]);
  const [messageApi, contextHolder] = message.useMessage();
  const [lastValue, setLastValue] = useState({
    fridge_1: 0,
    fridge_2: 0,
    fridge_3: 0,
  });
  const [data, setData] = useState({
    fridge_1: {
      series: [0],
      dataCh: [0],
      math: { max: 0, min: 0, last: 10, avg: 0 },
    },
    fridge_2: {
      series: [0],
      dataCh: [0],
      math: { max: 0, min: 0, last: 10, avg: 0 },
    },
    fridge_3: {
      series: [0],
      dataCh: [0],
      math: { max: 0, min: 0, last: 10, avg: 0 },
    },
  });
  /**
   * Peticion
   */
  const getData = (param) => {
    axios
      .post(`${server()}/mg/data/refrigerators`, param, {
        headers: {
          "Content-Type": "application/json",
          widthCredentials: false,
        },
      })
      .then((response) => {
        setLastValue({
          fridge_1: response.data.fridge_1.math.last,
          fridge_2: response.data.fridge_2.math.last,
          fridge_3: response.data.fridge_3.math.last,
        });
        setData(response.data);
        messageApi.success("Data Loaded Successfully.");
      })
      .catch((e) => {
        messageApi.error(e);
        console.log(e);
      });
  };
  /**
   * Card
   */
  const cart = [
    {
      id: 1,
      name: "Dairy",
      icon: <CiTempHigh />,
      values: {
        max: {
          name: <Title level={5}>Max</Title>,
          value: data.fridge_1.math.max.toFixed(1),
        },
        min: {
          name: <Title level={5}>Min</Title>,
          value: data.fridge_1.math.min.toFixed(1),
        },
        avg: {
          name: <Title level={5}>Average</Title>,
          value: data.fridge_1.math.avg.toFixed(1),
        },
      },
      config: {
        data: data.fridge_1.dataCh,
        xField: "date",
        yField: "value",
        seriesField: "name",
        slider: {
          start: 0.5,
          end: 1,
        },
        color: ["#FFC73A"],

        autoFit: true,
      },

      last: lastValue.fridge_1.toFixed(1),
      unit: "ºC",
    },
    {
      id: 2,
      name: "Meat and Sausages",
      icon: <CiTempHigh />,
      values: {
        max: {
          name: <Title level={5}>Max</Title>,
          value: data.fridge_2.math.max.toFixed(1),
        },
        min: {
          name: <Title level={5}>Min</Title>,
          value: data.fridge_2.math.min.toFixed(1),
        },
        avg: {
          name: <Title level={5}>Average</Title>,
          value: data.fridge_2.math.avg.toFixed(1),
        },
      },
      config: {
        data: data.fridge_2.dataCh,
        xField: "date",
        yField: "value",
        seriesField: "name",
        slider: {
          start: 0.5,
          end: 1,
        },
        color: ["#1A2793"],

        autoFit: true,
      },

      last: lastValue.fridge_2.toFixed(1),
      unit: "ºC",
    },
    {
      id: 3,
      name: "Vegetables",
      icon: <CiTempHigh />,
      values: {
        max: {
          name: <Title level={5}>Max</Title>,
          value: data.fridge_3.math.max.toFixed(1),
        },
        min: {
          name: <Title level={5}>Min</Title>,
          value: data.fridge_3.math.min.toFixed(1),
        },
        avg: {
          name: <Title level={5}>Average</Title>,
          value: data.fridge_3.math.avg.toFixed(1),
        },
      },
      config: {
        data: data.fridge_3.dataCh,
        xField: "date",
        yField: "value",
        seriesField: "name",
        slider: {
          start: 0.5,
          end: 1,
        },
        color: ["#CF1322"],

        autoFit: true,
      },

      last: lastValue.fridge_3.toFixed(1),
      unit: "ºC",
    },
  ];

  useEffect(() => {
    getData({
      id_device: "c4f412fa437be4c5",
      date: { $gte: params.date[0], $lte: params.date[1] },
    });
  }, []);
  // useEffect(() => {
  //   const eventSource = new EventSource(
  //     `${server()}/sse/iot-events/c4f412fa437be4c5`,
  //     {
  //       withCredentials: false,
  //     }
  //   );
  //   eventSource.onopen = () => {
  //     console.log("Connected");
  //   };
  //   eventSource.onmessage = (event) => {
  //     let newData = JSON.parse(event.data);
  //     setLastValue(newData);
  //     messageApi.success("Data Updated");
  //   };
  //   eventSource.onerror = (error) => {
  //     console.log(error);
  //   };
  //   return () => {
  //     eventSource.close();
  //   };
  // }, []);
  return (
    <>
      <div className="layout-content">
        {/**
         * Card de las Variables
         */}
        {contextHolder}
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          {cart.map((v, index, _) => (
            <Col
              key={index}
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={8}
              xxl={8}
              className="mb-24"
            >
              <Card bordered={false}>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <Card bordered={true} title={v.name}>
                      <Title level={4}>
                        {v.icon} {` Temperature: ${v.last} ${v.unit}`}
                      </Title>
                    </Card>
                  </Col>
                </Row>
                <Divider />
                <Row>
                  <Col xs={24} sm={8} md={8} lg={12} xl={12} xxl={8}>
                    <Card bordered={true}>
                      <Title level={5}>
                        {v.icon} {v.values.max.value} {v.unit}
                        <Meta description={v.values.max.name} />
                      </Title>
                    </Card>
                  </Col>
                  <Col xs={24} sm={8} md={8} lg={12} xl={12} xxl={8}>
                    <Card bordered={true}>
                      <Title level={5}>
                        {v.icon} {v.values.min.value} {v.unit}
                        <Meta description={v.values.min.name} />
                      </Title>
                    </Card>
                  </Col>
                  <Col xs={24} sm={8} md={8} lg={12} xl={12} xxl={8}>
                    <Card bordered={true}>
                      <Title level={5}>
                        {v.icon} {v.values.avg.value} {v.unit}
                        <Meta description={v.values.avg.name} />
                      </Title>
                    </Card>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
        <Row>
          {" "}
          {cart.map((c, i, _) => (
            <Col
              key={i}
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={8}
              xxl={8}
              className="mb-24"
            >
              <Card bordered={false}>
                <Line {...c.config} />
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
}
