import { Button, Row, Col, Modal, Card, Statistic } from "antd";
import { useEffect, useState } from "react";
import { Line } from "@ant-design/plots";
import { AiOutlineArrowDown } from "react-icons/ai";
import { IoArrowUpSharp } from "react-icons/io5";
import { BiShow } from "react-icons/bi";
import { TbMathAvg } from "react-icons/tb";
const { Meta } = Card;
export default function Triphasic(data) {
  const [modal2Open, setModal2Open] = useState(false);
  const [value, setValue] = useState({
    dataCh: [],
    data: {
      l1: { max: 0, min: 0, avg: 0, last: 0 },
      l2: { max: 0, min: 0, avg: 0, last: 0 },
      l3: { max: 0, min: 0, avg: 0, last: 0 },
    },
    unit: " ",
  });

  const cart = [
    {
      id: 1,
      name: "Line 1",
      values: {
        avg: value.data.l1.avg.toFixed(2),
        max: value.data.l1.max.toFixed(2),
        min: value.data.l1.min.toFixed(2),
      },
      unit: data.unit,
    },
    {
      id: 2,
      name: "Line 2",
      values: {
        avg: value.data.l2.avg.toFixed(2),
        max: value.data.l2.max.toFixed(2),
        min: value.data.l2.min.toFixed(2),
      },
      unit: data.unit,
    },
    {
      id: 3,
      name: "Line 3",
      values: {
        avg: value.data.l3.avg.toFixed(2),
        max: value.data.l3.max.toFixed(2),
        min: value.data.l3.min.toFixed(2),
      },
      unit: data.unit,
    },
  ];
  const config = {
    data: value.dataCh,
    xField: "date",
    yField: "value",
    seriesField: "name",
    slider: {
      start: 0.7,
      end: 1,
    },
    color: ["#FFC73A", "#1A2793", "#CF1322"],
    autoFit: true,
  };

  useEffect(() => {
    setValue(data);
  }, [data]);

  return (
    <>
      <Row>
        <Col
          className="mb-24"
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          xxl={24}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button type="primary" onClick={() => setModal2Open(true)}>
            Show Details {<BiShow size={25} />}
          </Button>
          <Modal
            title="Details to Variable"
            width={"80%"}
            centered
            open={modal2Open}
            onOk={() => setModal2Open(false)}
            onCancel={() => setModal2Open(false)}
          >
            <Row>
              {cart.map((cart) => (
                <Col
                  key={cart.id}
                  className="mb-24"
                  xs={8}
                  sm={8}
                  md={8}
                  lg={7}
                  xl={8}
                  xxl={8}
                >
                  <Card title={cart.name} bordered={true}>
                    <Row>
                      <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                        <Card bordered={true}>
                          <Statistic
                            value={`${cart.values.avg} ${cart.unit}`}
                            valueStyle={{
                              color: "#08979c",
                            }}
                            prefix={<TbMathAvg />}
                          />
                          <Meta description={"Avg"} />
                        </Card>
                      </Col>
                      <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                        <Card bordered={true}>
                          <Statistic
                            value={`${cart.values.max} ${cart.unit}`}
                            valueStyle={{
                              color: "#52C41A",
                            }}
                            prefix={<IoArrowUpSharp />}
                          />
                          <Meta description={"Max"} />
                        </Card>
                      </Col>
                      <Col
                        className="mb-24"
                        xs={8}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={8}
                        xxl={8}
                      >
                        <Card bordered={true}>
                          <Statistic
                            value={`${cart.values.min} ${cart.unit}`}
                            valueStyle={{
                              color: "red",
                            }}
                            prefix={<AiOutlineArrowDown />}
                          />
                          <Meta description={"Min"} />
                        </Card>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ))}
            </Row>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              
                <Card title="Comparison Chart" bordered={true}>
                
                  <Line {...config} />
                </Card>
              </Col>
            </Row>
          </Modal>
        </Col>
      </Row>
    </>
  );
}
