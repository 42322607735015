/**
 * Antd
 */
import "antd/dist/reset.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import "./assets/styles/style.css";
import "./App.css";
/**
 * Proveedor de keycloak
 */
import { ReactKeycloakProvider } from "@react-keycloak/web";
/**
 * Servicio Keycloak
 */
import keycloakAuth from "./api/service/auth.js";
/**
 * Rutas
 */
import AuthRoutes from "./routes/authRoutes.js";
/**
 * Proveedor de Configuraciones Antd
 */
import { ConfigProvider } from "antd";
import ControlPanelProvider from "./context/controlPanel";

/**
 *
 * @returns Funcion Padre
 */
function App() {
  return (
    /**
     * Configuraciones de Antd
     */
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#FA8C0B",
        },
      }}
    >
      <div className="App">
        {/**Configuraciones de Keycloak */}
        <ReactKeycloakProvider authClient={keycloakAuth}>
          <ControlPanelProvider>
            <AuthRoutes />
          </ControlPanelProvider>
        </ReactKeycloakProvider>
      </div>
    </ConfigProvider>
  );
}
export default App;
